import i18n from "i18next";
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";
import en_translations from '../src/translations/en/messages.json'
import es_translations from '../src/translations/es/messages.json'
import hi_translations from '../src/translations/hi/messages.json'
import id_translations from '../src/translations/id/messages.json'
import pt_translations from '../src/translations/pt/messages.json'
import pt_br_translations from '../src/translations/pt_BR/messages.json'

i18n
  // Add React bindings as a plugin.
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    // Config options

    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We use English here, but feel free to use
    // whichever locale you want.                   
    lng: "en",
    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here. 
    fallbackLng: "en",
    // Enables useful output in the browser’s
    // dev console.
    debug: true,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn 
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    // Translation messages. Add any languages
    // you want here.
    resources: {
      // English
      en: {
        translation: {
          ...en_translations
        },
      },
      es: {
        translation: {
          ...es_translations,
        },
      },
      hi: {
        translation: {
          ...hi_translations,
        },
      },
      id: {
        translation: {
          ...id_translations,
        },
      },
      pt: {
        translation: {
          ...pt_translations,
        },
      },
      pt_BR: {
        translation: {
          ...pt_br_translations,
        },
      },
      pt_PT: {
        translation: {
          ...pt_translations,
        },
      },
      default: {
        translation: en_translations
      }
    },
  });

export default i18n;
