import { QuickRepliesTypes } from "./quickreplies.types";

const INITITAL_STATE = {
  quickRepliesData: [],
  quickRepliesSplineData: [],
  showLoaderQuickReplies: true,
};

 const quickRepliesReducer = (state = INITITAL_STATE, action) => {
  switch (action.type) {
    case QuickRepliesTypes.GET_QUICK_REPLIES_SUCCESS:
      return {
        ...state,
        quickRepliesData: action.payload,
      };
    case QuickRepliesTypes.GET_SPLINE_CHART_QUICK_REPLIES_SUCCESS:
      return {
        ...state,
        quickRepliesSplineData: action.payload,
      };
    
    case QuickRepliesTypes.GET_QUICK_REPLIES_TABULAR_DATA_SUCCESS:
      return {
        ...state,
        quickRepliesTabularData: action.payload
      }

    case QuickRepliesTypes.QUICK_REPLIES_ANALYTICS_SUCCESS:
      return {
        ...state,
        quickRepliesAnalytics: action.payload
      }

    case QuickRepliesTypes.UPDATE_QUICK_REPLIES_LOADER:
      return {
          ...state,
          showLoaderQuickReplies: action.payload
      }



    default:
      return {
        ...state,
      };
  }
};

export default quickRepliesReducer
