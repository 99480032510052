import { Box, Modal } from "@mui/material";
import React from "react";
import "./trialendedstickytopcomp.scss";
import closeBtnImg from '../../../assets/icons/close-btn-white.svg'
import { Link } from "react-router-dom";
import UpgradePlansPopup from "../UpgradePlansPopup/UpgradePlansPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: "2rem!important",
  // height: "32rem",
  borderRadius: "10px",
  // height: "16rem",
};

const ExcIconSvg = ({ className }) => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1426_5431)">
      <g filter="url(#filter0_d_1426_5431)">
        <path d="M9.00775 0.0078125C9.00536 0.0078125 9.00218 0.00820975 8.9998 0.00820975C8.99702 0.00820975 8.99464 0.0078125 8.99186 0.0078125C4.02617 0.0078125 0 4.03359 0 9.00007C0 13.9657 4.02617 17.9919 8.99186 17.9919C8.99464 17.9919 8.99702 17.9919 8.9998 17.9919C9.00218 17.9919 9.00536 17.9919 9.00775 17.9919C13.9734 17.9919 17.9996 13.9657 17.9996 9.00007C18 4.03359 13.9738 0.0078125 9.00775 0.0078125ZM9.00775 15.4177C9.00536 15.4177 9.00218 15.4173 8.9998 15.4173C8.99702 15.4173 8.99464 15.4177 8.99186 15.4177C8.17907 15.4177 7.52003 14.7591 7.52003 13.9459C7.52003 13.1335 8.17907 12.4749 8.99186 12.4749C8.99464 12.4749 8.99702 12.4749 8.9998 12.4749C9.00218 12.4749 9.00536 12.4749 9.00775 12.4749C9.82013 12.4749 10.48 13.1335 10.48 13.9459C10.48 14.7587 9.82132 15.4177 9.00775 15.4177ZM11.1184 4.20203C11.0222 5.67068 10.3509 10.0385 10.3509 10.0385C10.3509 10.775 9.74743 11.3717 9.01092 11.3717C9.00695 11.3717 9.00298 11.3717 8.9994 11.3717C8.99583 11.3717 8.99146 11.3717 8.98788 11.3717C8.25177 11.3717 7.64834 10.7742 7.64834 10.0385C7.64834 10.0385 6.97738 5.67068 6.88124 4.20203C6.83357 3.46671 7.39688 2.10134 8.98828 2.10134C8.99225 2.10134 8.99623 2.10174 8.9998 2.10174C9.00338 2.10174 9.00735 2.10134 9.01132 2.10134C10.6023 2.10134 11.1664 3.46671 11.1184 4.20203Z" fill="white" />
      </g>
    </g>
    <defs>
      <filter id="filter0_d_1426_5431" x="-10" y="-9.99219" width="38" height="37.9839" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1426_5431" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1426_5431" result="shape" />
      </filter>
      <clipPath id="clip0_1426_5431">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>

)

const TrialEndedStickyTopComp = ({ openPopup, handleClose, content, userPlanDetails }) => {
  const [plansPopup, setPlansPopup] = useState(false);

  function handlePlansPopupClose() {
    setPlansPopup(false);
  }

  const handleUpgradeNow = () => {
    window.open(userPlanDetails.data?.manageBillingURL)
  }

  const { t } = useTranslation()

  return (
    openPopup &&
    <>
      <UpgradePlansPopup openPopup={plansPopup} handleClose={handlePlansPopupClose} />
      <div className="trial-ended-sticky-container d-flex-row">
        <div className="inner-part d-flex">
          <ExcIconSvg className="exc-icon mr-2" />
          <p className="trial-exp-txt mr-4">{content}</p>
          {
            userPlanDetails && userPlanDetails.data?.manageBillingURL ?
              (
                <button className="upgrade-btn mr-4" onClick={handleUpgradeNow}>
                  {t('Upgrade_now.message')}
                </button>
              ) : (
                <Link to={"/settings/billing"}>
                  <button className="upgrade-btn mr-4">
                    {t('Upgrade_now.message')}
                  </button>
                </Link>
              )
          }

          <img onClick={handleClose} className="close-btn mr-2 cursor-pointer" src={closeBtnImg} alt="close" />
        </div>
      </div>
    </>
  );
};

export default TrialEndedStickyTopComp;
