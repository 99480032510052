import DashboardActionTypes from "./dashboard.types";
import IncomingIcon from "../../assets/icons/incoming.svg"
import OutgoingIcon from "../../assets/icons/outgoing.svg"
import MissedIcon from "../../assets/icons/missed.svg"
import RejectedIcon from "../../assets/icons/rejected.svg"
import NotPickedIcon from "../../assets/icons/not_picked_up_by_client.svg"
import NeverAttendedIcon from "../../assets/icons/never_attented.svg"

const INITIAL_STATE = {
    organisationId: 0,
    callSections: [
        {
            type: 1,
            name: "Incoming",
            image:IncomingIcon,
            imageBackground: "rgba(93, 133, 211, 0.3)"
        },
        {
            type: 2,
            name: "Outgoing",
            image:OutgoingIcon,
            imageBackground: "rgba(95, 189, 166, 0.3)"
        },
        {
            type: 3,
            name: "Never Attended",
            image:MissedIcon,
            imageBackground: "rgba(255, 117, 76, 0.4)"
        },
        {
            type: 5,
            name: "Rejected By Client",
            image:RejectedIcon,
            imageBackground: "rgba(240, 38, 38, 0.3)"
        },
        // {
        //     type: 6,
        //     name: "Never Attended",
        //     image:NeverAttendedIcon,
        //     imageBackground: "rgba(240, 38, 38, 0.3)"
        // },
        // {
        //     type: 7,
        //     name: "Not Picked up",
        //     image:NotPickedIcon,
        //     imageBackground: "rgba(255, 122, 0, 0.3)"
        // },
    ],

     
      totalCallCountSpline: [],
      totalCallDurationSpline: [],
      uniqueCallCountSpline: [],
      newCallsCountSpline: [],
      callsOverView: null,
      showLoader: true,
      newCallsOverView: null,
      activeHours: null,
      activeHoursChat: null,
      callLogsList: null,
      nextTokenCallLogs: null,
      employeeCallLogs: null,
      employeeNextTokenCallLogs: null,
      callsOverViewDateComparision: null


  }

  const dashboardReducer = (state = INITIAL_STATE,action) => {

    switch (action.type) {
        case DashboardActionTypes.SET_ORGANISATION_ID: 
        return{
            ...state,
            organisationId: action.payload
        }

                case DashboardActionTypes.UPDATE_TOTAL_CALLS_COUNT_SPLINE:
                    return{
                        ...state,
                        totalCallCountSpline: action.payload
                    }

                    case DashboardActionTypes.UPDATE_TOTAL_CALLS_DURATION_SPLINE:
                        return{
                            ...state,
                            totalCallDurationSpline: action.payload
                        }

                        case DashboardActionTypes.UPDATE_UNIQUE_CALLS_COUNT_SPLINE:
                            return{
                                ...state,
                                uniqueCallCountSpline: action.payload
                            }

                            case DashboardActionTypes.UPDATE_NEW_CALLS_COUNT_SPLINE:
                                return{
                                    ...state,
                                    newCallsCountSpline: action.payload
                                }

                            case 
                            DashboardActionTypes.UPDATE_CALLS_OVERVIEW:
                            return {
                                ...state,
                                callsOverView: action.payload
                            }
                            case 
                            DashboardActionTypes.UPDATE_DASHBOARD_LOADER:
                            
                                return {
                                    ...state,
                                    showLoader: action.payload
                                }
                            
                            

                            case DashboardActionTypes.UPDATE_NEW_CALLS_OVERVIEW:
                            return {
                                ...state,
                                newCallsOverView: action.payload
                            }

                            case DashboardActionTypes.UPDATE_ACTIVE_HOURS:
                                return {
                                    ...state,
                                    activeHours: action.payload
                                }

                                case DashboardActionTypes.UPDATE_ACTIVE_HOURS_CHAT:
                                    return {
                                        ...state,
                                        activeHoursChat: action.payload
                                    }

                            case DashboardActionTypes.UPDATE_CALL_LOGS:
                                if(!action.reset && state.nextTokenCallLogs  && state.nextTokenCallLogs !== action.token) {
                                    return {
                                        ...state,
                                        callLogsList: state.callLogsList.concat(action.callLogsList),
                                        nextTokenCallLogs: action.token
                                    }
                                } else {
                                    return {
                                        ...state,
                                        callLogsList: action.callLogsList,
                                        nextTokenCallLogs: action.token
                                    }
                                }

                            case DashboardActionTypes.UPDATE_EMPLOYEE_CALL_LOGS:
                                if(state.employeeNextTokenCallLogs && state.employeeNextTokenCallLogs !== action.token) {
                                    return {
                                        ...state,
                                        employeeCallLogs: state.employeeCallLogs.concat(action.callLogsList),
                                        employeeNextTokenCallLogs: action.token
                                    }
                                } else {
                                    return {
                                        ...state,
                                        employeeCallLogs: action.callLogsList,
                                        employeeNextTokenCallLogs: action.token
                                    }
                                }

                                case DashboardActionTypes.GET_EMPLOYEE_CALL_LOGS_RESET:
                                    return {
                                        ...state,
                                        employeeCallLogs: null,
                                        employeeNextTokenCallLogs: null
                                    }

                                case DashboardActionTypes.CALL_OVERVIEW_COMPARITION_BY_DATE:
                                    return {
                                        ...state,
                                        callsOverViewDateComparision: action.payload
                                    }
             
    
        default:
            return {
                ...state
              }
    }
  }

  export default dashboardReducer;