import { subDays } from "date-fns";
import { DateTypes, setDateToFirst } from "../../util/helper";
import LayoutTypes from "./layout.types";

const INITIAL_STATE = {
    sideBar: false,
    filterDate: {
    startDate: subDays(new Date(),7).toJSON().split("T")[0] + " 00:00:00",
    endDate: new Date().toJSON().split("T")[0] + " 23:59:59"
    },
    activeEmployee: 'All',
    activeTeam: {
        id: 'All',
        name: 'All',
    },
    currentNavigation: "",
    selectedDateType: DateTypes.THIS_MONTH
}

const layoutReducer = (state = INITIAL_STATE,action) => {

    switch (action.type) {
        case LayoutTypes.TOGGLE_LEFTMENU:
            return{
                ...state,
                sideBar: !state.sideBar
            }
        case LayoutTypes.UPDATE_SELECTED_EMPLOYEE:
            return{
                ...state,
                activeEmployee: action.employee
            }

            case LayoutTypes.UPDATE_SELECTED_TEAM:
                return{
                    ...state,
                    activeTeam: {
                        id: action.team,
                        name: action.name
                    }
                }

        case LayoutTypes.UPDATE_FILTER_DATES: 
        return{
            ...state,
            filterDate: {
                startDate: action.startDate,
                endDate: action.endDate
            }
        }

        case LayoutTypes.UPDATE_CURRENT_NAVIGATION:
            return {
                ...state,
                currentNavigation: action.payload
            }

        case LayoutTypes.UPDATE_DATE_TYPES:
            return {
                ...state,
                filterDate: {
                    startDate: action.startDate,
                    endDate: action.endDate
                },
                selectedDateType: action.dateType
            }
    
        default:
            return {
                ...state
              }
    }
} 

export default layoutReducer;