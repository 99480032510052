export const QuickRepliesTypes = {
    GET_QUICK_REPLIES_START: "get_quick_replies_start",
    GET_QUICK_REPLIES_SUCCESS: "get_quick_replies_success",
    GET_QUICK_REPLIES_FAILURE: "get_quick_replies_failure",

    GET_SPLINE_CHART_QUICK_REPLIES_START: "get_spline_chart_quick_replies_start",
    GET_SPLINE_CHART_QUICK_REPLIES_SUCCESS: "get_spline_chart_quick_replies_success",
    GET_SPLINE_CHART_QUICK_REPLIES_FAILURE: "get_spline_chart_quick_replies_failure",


    GET_QUICK_REPLIES_TABULAR_DATA_START: "get_quick_replies_tabular_data_start",
    GET_QUICK_REPLIES_TABULAR_DATA_SUCCESS: "get_quick_replies_tabular_data_success",
    GET_QUICK_REPLIES_TABULAR_DATA_FAILURE: "get_quick_replies_tabular_data_failure",

    QUICK_REPLIES_ANALYTICS_START: "quick_reply_analytics_start",
    QUICK_REPLIES_ANALYTICS_SUCCESS: "quick_reply_analytics_success",
    QUICK_REPLIES_ANALYTICS_FAILURE: "quick_reply_analytics_failure",

    UPDATE_QUICK_REPLIES_LOADER: "UPDATE_QUICK_REPLIES_LOADER"
}