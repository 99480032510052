import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { AddCircle, DeleteForever } from '@material-ui/icons';

const DynamicAssignment = () => {
  const [activeMembers, setActiveMembers] = useState([]);
  const [allMembers, setAllMembers] = useState([]);

  function fetchMemebers() {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch("https://eazybe.com/api/v1/whatzapp/dashboard/dynamic-assignment", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.result) {
          setActiveMembers(result.activeMembers);
          setAllMembers(result.allMembers);
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    fetchMemebers();
  }, [])

  useEffect(() => {
    console.log(activeMembers, allMembers)
  }, [activeMembers, allMembers])

  const removeMember = async (ownerId) => {
    const requestOptions = {
      method: "DELETE",
      redirect: "follow"
    };
    
    fetch(`https://eazybe.com/api/v1/whatzapp/dashboard/dynamic-assignment?owner_id=${ownerId}`, requestOptions)
      .then((response) => response.text())
      .then((result) => fetchMemebers())
      .catch((error) => console.error(error));
  }

  const addMember = async (name, ownerId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "name": name,
      "owner_id": ownerId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://eazybe.com/api/v1/whatzapp/dashboard/dynamic-assignment", requestOptions)
      .then((response) => response.text())
      .then((result) => fetchMemebers())
      .catch((error) => console.error(error));
  }

  return (
    <div className={styles.main_container}>
      <div className={styles.active_members}>
        <h4>Active Members</h4>
        <ul>
          {activeMembers.map(member => (
            <li>
              <span>
                {member.employee_name}
              </span>
              <span onClick={() => removeMember(member.hubspot_owner_id)}>
                <DeleteForever />
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.all_members}>
        <h4>All Members</h4>
        <ul>
          {allMembers.map(member => (
            <li>
              <span>
                {member.firstName}
              </span>
              <span onClick={() => addMember(member.firstName, member.id)}>
                <AddCircle />
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DynamicAssignment