const DashboardActionTypes = {
    GET_USER_CALL_LOGS_START: 'GET_USER_CALL_LOGS_START',
    GET_USER_CALL_LOGS_SUCCESS: 'GET_USER_CALL_LOGS_SUCCESS',
    GET_USER_CALL_LOGS_FAILED: 'GET_USER_CALL_LOGS_FAILED',
    GET_ORGANISATION_LOGS_START: 'GET_ORGANISATION_LOGS_START',
    GET_ORGANISATION_LOGS_SUCCESS: 'GET_ORGANISATION_LOGS_SUCCESS',
    GET_ORGANISATION_LOGS_FAILED: 'GET_ORGANISATION_LOGS_FAILED',
    SET_ORGANISATION_ID: 'SET_ORGANISATION_ID',

    GET_TOP_PERFORMER_START: 'GET_TOP_PERFORMER_START',
    GET_TOP_PERFORMER_SUCCESS: 'GET_TOP_PERFORMER_SUCCESS',
    GET_TOP_PERFORMER_SUCCESS_OUTGOING: 'GET_TOP_PERFORMER_SUCCESS_OUTGOING',
    GET_TOP_PERFORMER_FAILED: 'GET_TOP_PERFORMER_FAILED',
    GET_TOP_PERFORMER_FAILED_OUTGOING: 'GET_TOP_PERFORMER_FAILED_OUTGOING',

    GET_NEED_ATTENTION_START: 'GET_NEED_ATTENTION_START',
    GET_NEED_ATTENTION_SUCCESS: 'GET_NEED_ATTENTION_SUCCESS',
    GET_NEED_ATTENTION_SUCCESS_OUTGOING: 'GET_NEED_ATTENTION_SUCCESS_OUTGOING',
    GET_NEED_ATTENTION_FAILED: 'GET_NEED_ATTENTION_FAILED',
    GET_NEED_ATTENTION_FAILED_OUTGOING: 'GET_NEED_ATTENTION_FAILED_OUTGOING',

    GET_UNIQUE_CLIENT_START: 'GET_UNIQUE_CLIENT_START',
    GET_UNIQUE_CLIENT_SUCCESS: 'GET_UNIQUE_CLIENT_SUCCESS',
    GET_UNIQUE_CLIENT_FAILED: 'GET_UNIQUE_CLIENT_FAILED',

    GET_ACTIVE_HOURS_START: 'GET_ACTIVE_HOURS_START',
    GET_ACTIVE_HOURS_SUCCESS: 'GET_ACTIVE_HOURS_SUCCESS',
    GET_ACTIVE_HOURS_FAILED: 'GET_ACTIVE_HOURS_FAILED',

    GET_SPLINE_CHART_START: 'GET_SPLINE_CHART_START',
    GET_SPLINE_CHART_SUCCESS: 'GET_SPLINE_CHART_SUCCESS',
    GET_SPLINE_CHART_FAILED: 'GET_SPLINE_CHART_FAILED',

    GET_UNIQUE_SPLINE_CHART_START: 'GET_UNIQUE_SPLINE_CHART_START',
    GET_UNIQUE_SPLINE_CHART_SUCCESS: 'GET_UNIQUE_SPLINE_CHART_SUCCESS',
    GET_UNIQUE_SPLINE_CHART_FAILED: 'GET_UNIQUE_SPLINE_CHART_FAILED',

    UPDATE_CALLS_SPLINE_CHART_CALL_TYPE: 'UPDATE_CALLS_SPLINE_CHART_CALL_TYPE',

    UPDATE_TOTAL_CALLS_COUNT_SPLINE: 'UPDATE_TOTAL_CALLS_COUNT_SPLINE', 
    UPDATE_TOTAL_CALLS_DURATION_SPLINE: 'UPDATE_TOTAL_CALLS_DURATION_SPLINE', 
    UPDATE_UNIQUE_CALLS_COUNT_SPLINE: 'UPDATE_UNIQUE_CALLS_COUNT_SPLINE',
    UPDATE_NEW_CALLS_COUNT_SPLINE: 'UPDATE_NEW_CALLS_COUNT_SPLINE', 
    
    UPDATE_CALLS_OVERVIEW: 'UPDATE_CALLS_OVERVIEW',
    UPDATE_DASHBOARD_LOADER : 'UPDATE_DASHBOARD_LOADER',
    UPDATE_NEW_CALLS_OVERVIEW: 'UPDATE_NEW_CALLS_OVERVIEW',
    UPDATE_ACTIVE_HOURS: 'UPDATE_ACTIVE_HOURS',
    UPDATE_ACTIVE_HOURS_CHAT: 'UPDATE_ACTIVE_HOURS_CHAT',

    UPDATE_CALL_LOGS: 'UPDATE_CALL_LOGS',
    UPDATE_EMPLOYEE_CALL_LOGS: 'UPDATE_EMPLOYEE_CALL_LOGS',
    GET_EMPLOYEE_CALL_LOGS_RESET: 'GET_EMPLOYEE_CALL_LOGS_RESET',
    CALL_OVERVIEW_COMPARITION_BY_DATE: 'CALL_OVERVIEW_COMPARITION_BY_DATE',
}

export default DashboardActionTypes;