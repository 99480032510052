import React, { useEffect, useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import styles from "./styles.module.scss";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  selectOrgCreditHistory,
  selectUserExistenceData,
} from "../../../redux/auth/auth.selector";
import { createStructuredSelector } from "reselect";
import { setUserActiveOrganization } from "../../../redux/organization/organization.action";
import { checkUserExistence } from "../../../redux/auth/auth.action";
import SwitchUser from "../SwitchUser/SwitchUser";

import diamondImg from "../../../assets/images/diamond.png";
import leftImg from "../../../assets/images/animated-right.png";
import { checkForPlanName } from "../../../util/helper";
import { selectDontShowUpgrade } from "../../../redux/organization/organization.selector";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "2rem!important",
  borderRadius: "10px",
};

const UpgradePlan = ({
  fetchedUserExistenceData,
  fetchedOrgCreditHistory,
  setActiveOrganization,
  fetchUserExistenceData,
  route = "/organization",
  dontShowUpgrade,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("Upgrade now to keep enjoying Eazybe");
  const [button, setButton] = useState("");
  const [isSwitchOpen, setIsSwitchOpen] = useState(false);
  const [isTeamsTrialUser, setIsTeamsTrialUser] = useState(false);
  const [showDismissBtn, setShowDismissBtn] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    const tempPlanData = fetchedOrgCreditHistory?.data;

    console.log("UpgradePlanPopup", {
      fetchedUserExistenceData,
      fetchedOrgCreditHistory,
      tempPlanData,
    });
    if (
      !fetchedUserExistenceData?.data?.callyzer_user_mappings[0]?.is_default_org
    ) {
      setHeading("Oops! Looks like you are on individual account!");
      setSubHeading("Switch now to teams to keep enjoying eazybe with all features.")
      setButton("Switch Now")
      setIsOpen(true)
    } else if (fetchedUserExistenceData?.data?.callyzer_user_mappings[0]?.is_default_org) {
      setHeading("Oops! Looks like your plan has expired!");
      // setSubHeading("Upgrade now to keep enjoying eazybe flat 23% off")
      setButton("Upgrade Now");

      const plusPlanUser = await checkForPlanName("Plus", tempPlanData);
      const freeUser = await checkForPlanName("Free", tempPlanData);
      const isNotLoggedInUser = await checkForPlanName("NotLoggedIn", tempPlanData);
      const teamsTrialUser = await checkForPlanName("TeamsTrial", tempPlanData);
      console.log("UpgradePlanPopup", {
        plusPlanUser,
        teamsTrialUser,
        isNotLoggedInUser
      });
      if(isNotLoggedInUser){
        setIsOpen(false);
      }
     else if(dontShowUpgrade){
        setIsOpen(false);
      }
       else if (freeUser) {
        setIsOpen(true);
        setHeading("You don't have Teams plan");
      } 
      // else if (teamsTrialUser) {
      //   setIsOpen(true);
      //   setHeading("You don't have Teams plan");
      //   // setIsTeamsTrialUser(true);
      //   setShowDismissBtn(true);
      // }

      // if (fetchedOrgCreditHistory.plan_status === "not-expired") {
      //   setIsOpen(false)
      // } else {
      //   setIsOpen(true)
      //   const plusPlanUser = await checkForPlanName("Plus",tempPlanData )
      //   const teamsTrialUser = await checkForPlanName("TeamsTrial",tempPlanData );
      //   console.log("UpgradePlanPopup", {
      //     plusPlanUser,
      //     teamsTrialUser
      //   })
      //   if(plusPlanUser){
      //     setHeading("You don't have Teams plan");
      //   }
      //   else if(teamsTrialUser){
      //     setHeading("You don't have Teams plan");
      //     setIsTeamsTrialUser(true);
      //   }
      // }
    }
  }, [fetchedOrgCreditHistory, dontShowUpgrade])

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <SwitchUser openPopup={isSwitchOpen} />
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="sync-mobile-app-container d-flex-col" sx={style}>
          <div className={styles.container}>
            <h3>{heading}</h3>
            <p>{subHeading}</p>
            {fetchedUserExistenceData?.data?.callyzer_user_mappings[0]?.is_default_org ? (
              <img src={diamondImg} alt="" height={300} />
            ) : (
              <img
                src={leftImg}
                alt=""
                height={300}
                style={{ marginTop: "1rem" }}
              />
            )}
            <div className={styles.buttons}>
              {fetchedUserExistenceData?.data?.callyzer_user_mappings[0]?.is_default_org ? (
                <>
                  <Link to="/settings/billing">
                    <button>{button}</button>
                  </Link>
                  <a
                    href="https://calendly.com/eazybe/eazybe-demo-call"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className={styles.secondBtn}>Book a demo</button>
                  </a>
                  {showDismissBtn && (
                    <button onClick={handleClose} className="dismiss-btn ml-1">Dismiss</button>
                  )}
                </>
              ) : (
                <button onClick={() => {
                  setActiveOrganization(localStorage.getItem("currentOrgId"));
                  setIsSwitchOpen(true)
                  setIsOpen(false);
                  setTimeout(() => {
                    fetchUserExistenceData(fetchedUserExistenceData?.data?.email)
                    setIsSwitchOpen(false)
                    history.push(route)
                  }, 3000)
                }}>Switch To Team</button>
              ) }
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  fetchedUserExistenceData: selectUserExistenceData,
  fetchedOrgCreditHistory: selectOrgCreditHistory,
  dontShowUpgrade : selectDontShowUpgrade,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveOrganization: (orgId) => dispatch(setUserActiveOrganization(orgId)),
  fetchUserExistenceData: (userEmail) =>
    dispatch(checkUserExistence(userEmail)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);
