import { CLOUD_TYPES } from "./cloud.types";

const INITIAL_STATE = {
    cloud_users: [],
}

const cloudReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CLOUD_TYPES.SAVE_CLOUD_USERS:
        return {
          ...state,
          cloud_users: action.payload,
        };

        default:
            return {
                ...state
            }
    }
}

export default cloudReducer;