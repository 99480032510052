import React from 'react';
import './bitrixnotconnected.scss';
import bitrixIconImg from '../../../../../assets/icons/bitrix-icon.png';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import ConfirmationModal from '../../../../../components/eazybe-components/ConfirmationModal/ConfirmationModal';
import showToast from '../../../../../components/Toasts/ShowToast';
import { useTranslation } from 'react-i18next';

const BitrixNotConnected = ({ onClickConnect, bitrixConnected, setBitrixConnected, sendMessageToChromeExtension }) => {
    const [unlinkModalActive, setUnlinkModalActive] = useState(false);
    const setUnlinkModalActiveTrue = () => setUnlinkModalActive(true);
    const setUnlinkModalActiveFalse = () => setUnlinkModalActive(false);


    let featuresArr = [
        "Create New Contacts",
        "Link Existing Contacts",
        "Update Contact Information",
        "Create Tasks",
        "Create Notes"
    ]

    const goBackToWhatsapp = () => {
        if (sendMessageToChromeExtension) {
            sendMessageToChromeExtension();
        }
        else {
            window.open('https://web.whatsapp.com/');
        }
    }


    const onClickUnlinkBitrix = () => {
        setUnlinkModalActiveTrue();
    }

    const unlinkBitrix = () => {
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        };

        fetch(`https://eazybe.com/api/v1/whatzapp/bitrix/deleteauth?workspace_id=${localStorage.getItem("workspaceId")}`, requestOptions)
            .then(response => response.json())
            .then(response => {

                if (response?.result) {
                    showToast("Unlinked Bitrix successfully");
                    setBitrixConnected(false);
                } else {
                    showToast("Something went wrong");

                }

            })
            .catch(error => console.log('error', error));
    }
    const { t } = useTranslation();

    return (
        <>
            <div className='hubspot-not-connected-con d-flex d-flex-col'>
                <div className='header-con d-flex'>
                    <img src={bitrixIconImg} className="hubspot-icon-big" />
                    <div className='connection-status d-flex d-flex-col'>
                        <div className='name-con d-flex'>
                            <h3>Bitrix</h3>

                            {
                                bitrixConnected ?
                                    <>
                                        <div className='d-flex align-items-center'>
                                            <DoneIcon htmlColor='green' fontSize="24px" />
                                            <p className='ml-0-5'>Connected</p>
                                        </div>
                                    </>

                                    :
                                    <div className='d-flex align-items-center'>
                                        <ClearIcon htmlColor='red' fontSize="24px" />
                                        <p className='ml-0-5'>
                                            {t("Not_Connected.message")}
                                        </p>
                                    </div>
                            }

                        </div>
                        <p>Link Contacts, Create Contacts, Create Tasks</p>
                    </div>

                    {
                        bitrixConnected ? <div>
                            <button onClick={goBackToWhatsapp} className='connect-hubspot-btn'>Go back to WhatsApp</button>
                            <button onClick={onClickUnlinkBitrix} className='unlink-hubspot-btn'>Unlink</button>
                        </div>
                            :
                            <button onClick={onClickConnect} className='connect-hubspot-btn'>Connect to Bitrix</button>
                    }

                    {/* <button onClick={onClickConnect} className='connect-hubspot-btn'>Connect to Hubspot</button> */}

                </div>
                <div className='info-con d-flex-col'>
                    <h5>
                        {t("Features.message")}
                    </h5>
                    <div className='features'>
                        {
                            featuresArr.map((feature) => (
                                <div className='feature-item d-flex'>
                                    <img className='feature-img' src={bitrixIconImg} />
                                    <p className='feature-text'>{feature}</p>
                                </div>
                            ))
                        }
                    </div>
                    {

                    }
                </div>
            </div>
            <ConfirmationModal
                open={unlinkModalActive}
                handleClose={setUnlinkModalActiveFalse}
                onClickOk={unlinkBitrix}
                onClickCancel={setUnlinkModalActiveFalse}
                title="Unlink Hubspot"
                message={t("Are_you_sure_you_want_to_unlink.message") + " hubspot"}
                okText={t("Unlink.message")}
            />
        </>

    )
}

export default BitrixNotConnected