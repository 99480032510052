import React from "react";
import { DotIconSvg, SettingsIconSvg } from "../../../assets/icons/SvgIcons";
import "./integrationcard.scss";
import { checkForPermission } from "../../../util/commonfunctions";
import showToast from "../../../components/Toasts/ShowToast";
import { useTranslation } from "react-i18next";


const IntegrationCard = ({ title, details, logo, bgColor, onClickHandler, type, section, accessLevel, isConnected = false }) => {
  const { t } = useTranslation();
  const onClickCard = () => {
    switch (accessLevel) {

      case "admin":
        if (checkForPermission(0)) {
          onClickHandler(section);
        }
        else {
          showToast("Only Admins can access this section");
        }
        break;

      case "manager":
        if (checkForPermission(1)) {
          onClickHandler(section);
        }
        else {
          showToast("Only Admins, managers can access this section");
        }
        break;

      default:
        onClickHandler(section)
        break;

    }
  }

  return (
    <div className="integration-card-container d-flex-col">
      <div className={isConnected ? "connected d-flex" : "not-connected d-flex"}>
        {isConnected ? <DotIconSvg color="#32a84e" /> : <DotIconSvg color="#607d8b" />}
        <p >
          {isConnected ? t("Connected.message") : t("Not_Connected.message")}
        </p>
      </div >

      <div className="header d-flex-row">
        <div style={{ background: bgColor }} className="logo-container d-flex">
          <img className="logo" src={logo} />
        </div>
        <div className="title-container">
          <h6 className="title">{title}</h6>
          <h6 className="type">{type}</h6>
        </div>
      </div>
      <p className="details">{details}</p>
      <div className="configure-btn d-flex mt-1 cursor-pointer" onClick={onClickCard}>
        <SettingsIconSvg />
        <p>
          {t('Configure.message')}
        </p>
      </div>
    </div>
  );
};

export default IntegrationCard;
