import { createSelector } from 'reselect';
import publicRoutes from '../../routes';

const layout = state => state.layout;


export const selectChangeInSideBar = createSelector(
    [layout],
    layout => layout.sideBar
)
export const selectFilterDate = createSelector(
    [layout],
    layout => layout.filterDate
)

export const selectedEmployee = createSelector(
    [layout],
    layout => layout.activeEmployee
)
export const selectedTeam = createSelector(
    [layout],
    layout => layout.activeTeam
)

export const selectCurrentNavigation = createSelector(
    [layout],
    layout => publicRoutes.find(item => layout.currentNavigation?.includes(item.path))  ?? ""
)

export const selectedDateType = createSelector(
    [layout],
    layout => layout.selectedDateType
)