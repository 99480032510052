import React, { useEffect, useState } from 'react';
import './hubspotnotconnected.scss';
import hubspotIconImg from '../../../../../assets/icons/hubspot-big-icon.png';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmationModal from '../../../../../components/eazybe-components/ConfirmationModal/ConfirmationModal';
import showToast from '../../../../../components/Toasts/ShowToast';
import { useTranslation } from 'react-i18next';

const HubspotNotConnected = ({
  onClickConnect,
  connected,
  sendMessageToChromeExtension
}) => {
  const { t } = useTranslation();
  const [hubConnected, setHubConnected] = useState(false);
  const [unlinkModalActive, setUnlinkModalActive] = useState(false);

  useEffect(() => {
    // Initialize hubConnected state with the 'connected' prop value
    setHubConnected(connected);
  }, [connected]);

  // Features list
  const featuresArr = [
    t("Create_New_Contacts.message"),
    t("Link_Existing_Contacts.message"),
    t("Update_Contact_Information.message"),
    t("Create_Tasks.message"),
    t("Create_Notes.message")
  ];

  // Go back to WhatsApp or open WhatsApp web
  const goBackToWhatsapp = () => {
    if (sendMessageToChromeExtension) {
      sendMessageToChromeExtension();
    } else {
      window.open('https://web.whatsapp.com/');
    }
  };

  // Handle unlink Hubspot button click
  const onClickUnlinkHubspot = () => {
    setUnlinkModalActive(true);
  };

  // Handle unlinking Hubspot
  const unlinkHubspot = () => {
    const requestOptions = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    };

    fetch(`https://eazybe.com/api/v1/whatzapp/deletehubspotauthtoken?workspace_id=${localStorage.getItem("workspaceId")}`, requestOptions)
      .then((res) => res.json())
      .then((response) => {
        if (response?.result) {
          showToast("Unlinked Hubspot successfully");
          setHubConnected(false);
          setUnlinkModalActive(false);
        } else {
          // Handle error case
        }
      });
  };

  return (
    <>
      <div className='hubspot-not-connected-con d-flex d-flex-col'>
        <div className='header-con d-flex'>
          <img src={hubspotIconImg} className="hubspot-icon-big" />
          <div className='connection-status d-flex d-flex-col'>
            <div className='name-con d-flex'>
              <h3>HubSpot</h3>
              {hubConnected ? (
                <div className='d-flex align-items-center'>
                  <DoneIcon htmlColor='green' fontSize="24px" />
                  <p className='ml-0-5'>
                    {t('Connected.message')}
                  </p>
                </div>
              ) : (
                <div className='d-flex align-items-center'>
                  <ClearIcon htmlColor='red' fontSize="24px" />
                  <p className='ml-0-5'>
                    {t('Not_Connected.message')}
                  </p>
                </div>
              )}
            </div>
            <p>
              {t("Link_Contacts_Create_Contacts_Create_Tasks.message")}
            </p>
          </div>
          {hubConnected ? (
            <>
              <div>
                <button onClick={goBackToWhatsapp} className='connect-hubspot-btn'>Go back to WhatsApp</button>
                <button onClick={onClickUnlinkHubspot} className='unlink-hubspot-btn'>
                  {t("Unlink.message")}
                </button>
              </div>
              {/* <IntegrationsFunnelSelector /> */}
            </>

          ) : (
            <button onClick={onClickConnect} className='connect-hubspot-btn'>Connect to HubSpot</button>
          )}
        </div>
        <div className='info-con d-flex-col'>
          <h5>
            {t("Features.message")}
          </h5>
          <div className='features'>
            {featuresArr.map((feature, index) => (
              <div className='feature-item d-flex' key={index}>
                <img className='feature-img' src={hubspotIconImg} alt={`Feature icon for ${feature}`} />
                <p className='feature-text'>{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={unlinkModalActive}
        handleClose={() => setUnlinkModalActive(false)}
        onClickOk={unlinkHubspot}
        onClickCancel={() => setUnlinkModalActive(false)}
        title="Unlink Hubspot"
        message={t("Are_you_sure_you_want_to_unlink.message") + " Hubspot?"}
        okText={t("Unlink.message")}
      />
    </>
  );
}

export default HubspotNotConnected;
