import React, { useEffect } from "react";
import "./zohonotconnected.scss";
import zohoIcon from "../../../../../assets/icons/zoho-icon.png";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
// import AlertModal from "../../../../../components/eazybe-components/AlertModal/AlertModal";
import { useState } from "react";
import ConfirmationModal from "../../../../../components/eazybe-components/ConfirmationModal/ConfirmationModal";
import showToast from "../../../../../components/Toasts/ShowToast";
import { useTranslation } from "react-i18next";

let featuresArr = [
  "Create New Contacts",
  "Link Existing Contacts",
  "Update Contact Information",
  "Create Notes",
];


const ZohoNotConnected = ({
  onClickConnect,
  zohoIntegrated,
  setZohoIntegrated,
  sendMessageToChromeExtension,
}) => {
  const { t } = useTranslation();
  const [unlinkModalActive, setUnlinkModalActive] = useState(false);
  const setUnlinkModalActiveTrue = () => setUnlinkModalActive(true);
  const setUnlinkModalActiveFalse = () => setUnlinkModalActive(false);

  useEffect(() => {
    setZohoIntegrated(false);
  }, []);


  const goBackToWhatsapp = () => {
    if (sendMessageToChromeExtension) {
      sendMessageToChromeExtension();
    } else {
      window.open("https://web.whatsapp.com/");
    }
  };

  const onClickUnlinkZoho = () => {
    setUnlinkModalActiveTrue();
  };

  const unlinkZoho = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
    };
    fetch(
      `https://eazybe.com/api/v1/whatzapp/zoho/auth/token?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((response) => {
        //   console.log("response : ", response);
        if (response?.result) {
          // sendMessageToChromeExtension(true, 500);
          showToast("Unlinked Zoho successfully");
          setZohoIntegrated(false);
          setUnlinkModalActiveFalse();
        } else {
          showToast("Unable to Unlink Zoho");
        }
      });
  };

  return (
    <>
      <div className="hubspot-not-connected-con d-flex d-flex-col">
        <div className="header-con d-flex">
          <img src={zohoIcon} className="hubspot-icon-big" />
          <div className="connection-status d-flex d-flex-col">
            <div className="name-con d-flex">
              <h3>Zoho</h3>

              {zohoIntegrated ? (
                <>
                  <div className="d-flex align-items-center">
                    <DoneIcon htmlColor="green" fontSize="24px" />
                    <p className="ml-0-5">
                      {t("Connected.message")}
                    </p>
                  </div>
                </>
              ) : (
                <div className="d-flex align-items-center">
                  <ClearIcon htmlColor="red" fontSize="24px" />
                  <p className="ml-0-5">
                    {t("Not_Connected.message")}
                  </p>
                </div>
              )}
            </div>
            <p>
              {t("Link_Contacts_Create_Contacts_Create_Tasks.message")}
            </p>
          </div>

          {zohoIntegrated ? (
            <div>
              <button
                onClick={goBackToWhatsapp}
                className="connect-hubspot-btn"
              >
                Go back to WhatsApp
              </button>
              <button
                onClick={onClickUnlinkZoho}
                className="unlink-hubspot-btn"
              >
                {t("Unlink.message")}
              </button>
            </div>
          ) : (
            <button onClick={onClickConnect} className="connect-hubspot-btn">
              Conect to Zoho
            </button>
          )}
        </div>
        <div className="info-con d-flex-col">
          <h5>
            {t("Features.message")}
          </h5>
          <div className="features">
            {featuresArr.map((feature) => (
              <div className="feature-item d-flex">
                <img className="feature-img" src={zohoIcon} />
                <p className="feature-text">{feature}</p>
              </div>
            ))}
          </div>
          { }
        </div>
      </div>
      <ConfirmationModal
        open={unlinkModalActive}
        handleClose={setUnlinkModalActiveFalse}
        onClickOk={unlinkZoho}
        onClickCancel={setUnlinkModalActiveFalse}
        title="Unlink Zoho"
        message={t("Are_you_sure_you_want_to_unlink.message") + " Zoho"}
        okText={t("Unlink.message")}
      />
    </>
  );
};

export default ZohoNotConnected;
