import { FETCH, API, FETCH2, FETCHV2 } from '../../util/api';
import IntegrationsTypes from './integrations.types';


export const createAuthLeadsquared = (data) => {

  return new Promise((resolve, reject) => {
    FETCHV2({
      method: 'POST',
      url: `leadsquared/auth`,
      body : JSON.stringify(data),
    })
    .then(res => res.json())
    .then(res => {
      console.log("createAuthLeadsquared", {
        res
      });
      resolve(res)
    
    })
    .catch(error => {
      resolve(null);
    })
  })
}


export const getAuthLeadsquared = (data) => {

return new Promise((resolve, reject) => {
  FETCHV2({
    method: 'GET',
    url: `leadsquared/auth`,
  })
  .then(res => res.json())
  .then(res => {
    console.log("getAuthLeadsquared", {
      res
    });
    resolve(res)
  
  })
  .catch(error => {
    resolve(null);
  })
})
}


export const deleteAuthLeadsquared = (data) => {

return new Promise((resolve, reject) => {
  FETCHV2({
    method: 'DELETE',
    url: `leadsquared/auth`,
  })
  .then(res => res.json())
  .then(res => {
    console.log("deleteAuthLeadsquared", {
      res
    });
    resolve(res)
  
  })
  .catch(error => {
    resolve(null);
  })
})
}


export const createAuthSalesforce = (data) => {

    return new Promise((resolve, reject) => {
      FETCHV2({
        method: 'POST',
        url: `salesforce/auth`,
        body : JSON.stringify(data),
      })
      .then(res => res.json())
      .then(res => {
        console.log("createAuthSalesforce", {
          res
        });
        resolve(res)
      
      })
      .catch(error => {
        resolve(null);
      })
    })
}


export const getAuthSalesforce = (data) => {

  return new Promise((resolve, reject) => {
    FETCHV2({
      method: 'GET',
      url: `salesforce/auth`,
    })
    .then(res => res.json())
    .then(res => {
      console.log("getAuthSalesforce", {
        res
      });
      resolve(res)
    
    })
    .catch(error => {
      resolve(null);
    })
  })
}


export const deleteAuthSalesforce = (data) => {

  return new Promise((resolve, reject) => {
    FETCHV2({
      method: 'DELETE',
      url: `salesforce/auth`,
    })
    .then(res => res.json())
    .then(res => {
      console.log("deleteAuthSalesforce", {
        res
      });
      resolve(res)
    
    })
    .catch(error => {
      resolve(null);
    })
  })
}

export const fetchSalesforceContactRecordTypes = () => {

  return new Promise((resolve, reject) => {
    FETCHV2({
      method: 'GET',
      url: `salesforce/record-types`,
    })
    .then(res => res.json())
    .then(res => {
      console.log("fetchSalesforceContactRecordTypes", {
        res
      });
      resolve(res)
    
    })
    .catch(error => {
      resolve(null);
    })
  })
}
export const fetchSalesforceUserSettings = () => {

  return new Promise((resolve, reject) => {
    FETCHV2({
      method: 'GET',
      url: `salesforce/settings`,
    })
    .then(res => res.json())
    .then(res => {
      console.log("fetchSalesforceUserSettings res", {res});
      resolve(res?.data)
    
    })
    .catch(error => {
      resolve(null);
    })
  })
}

export const saveSalesforceUserSettings = (data) => {

  return new Promise((resolve, reject) => {
    FETCHV2({
      method: 'POST',
      url: `salesforce/settings`,
      body : JSON.stringify(data),
    })
    .then(res => res.json())
    .then(res => {
      console.log("saveSalesforceUserSettings", {
        res
      });
      resolve(res)
    
    })
    .catch(error => {
      resolve(null);
    })
  })
}
export const fetchSalesforceContactRecordFieldsById = (id) => {

  return new Promise((resolve, reject) => {
    FETCHV2({
      method: 'GET',
      url: `salesforce/record-type/fields?id=${id}`,
    })
    .then(res => res.json())
    .then(res => {
      console.log("fetchSalesforceContactRecordFieldById res", {res});
      resolve(res)
    })
    .catch(error => {
      resolve(null);
    })
  })
}

