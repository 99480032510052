export const QUERY_DASHBOARD_TYPES = {
    TOTAL_CALLS_COUNT: "TOTAL_CALLS_COUNT",
    TOTAL_CALLS_DURATION: "TOTAL_CALLS_DURATION",
    UNIQUE_CALLS_COUNT: "UNIQUE_CALLS_COUNT",
    NEW_CALLS: "NEW_CALLS",
    CALL_OVERVIEW: "CALL_OVERVIEW",
    NEW_CALL_OVERVIEW: "NEW_CALL_OVERVIEW",
    ACTIVE_HOURS: "ACTIVE_HOURS",
    ACTIVE_HOURS_CHATS: "ACTIVE_HOURS_CHATS",
    TOTAL_COMPARISION_IN_DAYS: "TOTAL_COMPARISION_IN_DAYS", 
}

//Total Messages Count (Date-Histogram) (Filter: On the Basis of CreatedByUser)
export let getQuery = async (startDate,endDate,contactArray,type) => 
{

    let contactMatchArray = await getContactsArray(contactArray)


    switch (type) {
        case QUERY_DASHBOARD_TYPES.TOTAL_CALLS_COUNT: 
       return (
        {
            "aggs": {
              "2": {
                "date_histogram": {
                  "field": "Datetime",
                  "calendar_interval": "1d",
                  "time_zone": "Asia/Calcutta",
                  "min_doc_count": 1
                },
                "aggs": {
                  "3": {
                    "terms": {
                      "field": "Direction.keyword",
                      "order": {
                        "_count": "desc"
                      },
                      "size": 5
                    }
                  }
                }
              }
            },
            "size": 0,
            "stored_fields": [
              "*"
            ],
            "script_fields": {},
            "docvalue_fields": [
              {
                "field": "Datetime",
                "format": "date_time"
              },
              {
                "field": "createdAt",
                "format": "date_time"
              },
              {
                "field": "updatedAt",
                "format": "date_time"
              }
            ],
            "_source": {
              "excludes": []
            },
            "query": {
              "bool": {
                "must": [],
                "filter": [
                  {
                    "match_all": {}
                  },
                  {
                    "bool": {
                      "should": contactMatchArray,
                      "minimum_should_match": 1
                    }
                  },
                  {
                    "range": {
                      "Datetime": {
                        "gte": new Date(startDate).toJSON(),
                        "lte": new Date(endDate).toJSON(),
                        "format": "strict_date_optional_time"
                      }
                    }
                  }
                ],
                "should": [],
                "must_not": []
              }
            }
          }
       )

        case QUERY_DASHBOARD_TYPES.TOTAL_CALLS_DURATION:
            return (
                {
                    "aggs": {
                      "2": {
                        "date_histogram": {
                          "field": "Datetime",
                          "calendar_interval": "1d",
                          "time_zone": "Asia/Calcutta",
                          "min_doc_count": 1
                        },
                        "aggs": {
                          "3": {
                            "terms": {
                              "field": "Direction.keyword",
                              "order": {
                                "1": "desc"
                              },
                              "size": 5
                            },
                            "aggs": {
                              "1": {
                                "sum": {
                                  "field": "Duration"
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    "size": 0,
                    "stored_fields": [
                      "*"
                    ],
                    "script_fields": {},
                    "docvalue_fields": [
                      {
                        "field": "Datetime",
                        "format": "date_time"
                      },
                      {
                        "field": "createdAt",
                        "format": "date_time"
                      },
                      {
                        "field": "updatedAt",
                        "format": "date_time"
                      }
                    ],
                    "_source": {
                      "excludes": []
                    },
                    "query": {
                      "bool": {
                        "must": [],
                        "filter": [
                          {
                            "match_all": {}
                          },
                          {
                            "bool": {
                              "should": contactMatchArray,
                              "minimum_should_match": 1
                            }
                          },
                          {
                            "range": {
                              "Datetime": {
                                "gte": new Date(startDate).toJSON(),
                                "lte": new Date(endDate).toJSON(),
                                "format": "strict_date_optional_time"
                              }
                            }
                          }
                        ],
                        "should": [],
                        "must_not": []
                      }
                    }
                  }
            )

        case QUERY_DASHBOARD_TYPES.UNIQUE_CALLS_COUNT:
        return(
            {
                "aggs": {
                  "2": {
                    "date_histogram": {
                      "field": "Datetime",
                      "calendar_interval": "1d",
                      "time_zone": "Asia/Calcutta",
                      "min_doc_count": 1
                    },
                    "aggs": {
                      "3": {
                        "terms": {
                          "field": "Direction.keyword",
                          "order": {
                            "1": "desc"
                          },
                          "size": 5
                        },
                        "aggs": {
                          "1": {
                            "cardinality": {
                              "field": "Chatid.keyword"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "size": 0,
                "stored_fields": [
                  "*"
                ],
                "script_fields": {},
                "docvalue_fields": [
                  {
                    "field": "Datetime",
                    "format": "date_time"
                  },
                  {
                    "field": "createdAt",
                    "format": "date_time"
                  },
                  {
                    "field": "updatedAt",
                    "format": "date_time"
                  }
                ],
                "_source": {
                  "excludes": []
                },
                "query": {
                  "bool": {
                    "must": [],
                    "filter": [
                      {
                        "match_all": {}
                      },
                      {
                        "bool": {
                          "should": contactMatchArray,
                          "minimum_should_match": 1
                        }
                      },
                      {
                        "range": {
                          "Datetime": {
                            "gte": new Date(startDate).toJSON(),
                            "lte": new Date(endDate).toJSON(),
                            "format": "strict_date_optional_time"
                          }
                        }
                      }
                    ],
                    "should": [],
                    "must_not": []
                  }
                }
              }
        )

        case QUERY_DASHBOARD_TYPES.NEW_CALLS: 
        return (
          {
            "aggs": {
              "2": {
                "date_histogram": {
                  "field": "createdAt",
                  "fixed_interval": "12h",
                  "time_zone": "Asia/Calcutta",
                  "min_doc_count": 1
                },
                "aggs": {
                  "3": {
                    "terms": {
                      "field": "Direction.keyword",
                      "order": {
                        "_count": "desc"
                      },
                      "size": 5
                    }
                  }
                }
              }
            },
            "size": 0,
            "stored_fields": [
              "*"
            ],
            "script_fields": {},
            "docvalue_fields": [
              {
                "field": "createdAt",
                "format": "date_time"
              },
              {
                "field": "updatedAt",
                "format": "date_time"
              }
            ],
            "_source": {
              "excludes": []
            },
            "query": {
              "bool": {
                "must": [],
                "filter": [
                  {
                    "match_all": {}
                  },
                  {
                    "bool": {
                      "should": contactMatchArray,
                      "minimum_should_match": 1
                    }
                  },
                  {
                    "range": {
                      "createdAt": {
                        "gte": new Date(startDate).toJSON(),
                        "lte": new Date(endDate).toJSON(),
                        "format": "strict_date_optional_time"
                      }
                    }
                  }
                ],
                "should": [],
                "must_not": []
              }
            }
          }
        )

        case QUERY_DASHBOARD_TYPES.CALL_OVERVIEW:
            return (
                {
                    "aggs": {
                      "2": {
                        "terms": {
                          "field": "Direction.keyword",
                          "order": {
                            "_count": "desc"
                          },
                          "size": 5
                        },
                        "aggs": {
                          "5": {
                            "terms": {
                              "field": "CreatedByUser.keyword",
                              "order": {
                                "_count": "desc"
                              },
                              "size": contactMatchArray.length
                            },
                            "aggs": {
                              "4": {
                                "sum": {
                                  "field": "Duration"
                                }
                              },
                              "6": {
                                "cardinality": {
                                  "field": "Chatid.keyword"
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    "size": 0,
                    "stored_fields": [
                      "*"
                    ],
                    "script_fields": {},
                    "docvalue_fields": [
                      {
                        "field": "Datetime",
                        "format": "date_time"
                      },
                      {
                        "field": "createdAt",
                        "format": "date_time"
                      },
                      {
                        "field": "updatedAt",
                        "format": "date_time"
                      }
                    ],
                    "_source": {
                      "excludes": []
                    },
                    "query": {
                      "bool": {
                        "must": [],
                        "filter": [
                          {
                            "match_all": {}
                          },
                          {
                            "bool": {
                              "should": contactMatchArray,
                              "minimum_should_match": 1
                            }
                          },
                          {
                            "range": {
                              "Datetime": {
                                "gte": new Date(startDate).toJSON(),
                                "lte": new Date(endDate).toJSON(),
                                "format": "strict_date_optional_time"
                              }
                            }
                          }
                        ],
                        "should": [],
                        "must_not": []
                      }
                    }
                  }
            )

            case QUERY_DASHBOARD_TYPES.NEW_CALL_OVERVIEW:
              return (
                {
                  "aggs": {
                    "2": {
                      "terms": {
                        "field": "CreatedByUser.keyword",
                        "order": {
                          "_count": "desc"
                        },
                        "size": 5
                      },
                      "aggs": {
                        "3": {
                          "terms": {
                            "field": "Direction.keyword",
                            "order": {
                              "_count": "desc"
                            },
                            "size": contactMatchArray.length
                          }
                        }
                      }
                    }
                  },
                  "size": 0,
                  "stored_fields": [
                    "*"
                  ],
                  "script_fields": {},
                  "docvalue_fields": [
                    {
                      "field": "createdAt",
                      "format": "date_time"
                    },
                    {
                      "field": "updatedAt",
                      "format": "date_time"
                    }
                  ],
                  "_source": {
                    "excludes": []
                  },
                  "query": {
                    "bool": {
                      "must": [],
                      "filter": [
                        {
                          "match_all": {}
                        },
                        {
                          "bool": {
                            "should": contactMatchArray,
                            "minimum_should_match": 1
                          }
                        },
                        {
                          "range": {
                            "createdAt": {
                              "gte": new Date(startDate).toJSON(),
                                "lte": new Date(endDate).toJSON(),
                              "format": "strict_date_optional_time"
                            }
                          }
                        }
                      ],
                      "should": [],
                      "must_not": []
                    }
                  }
                }
              )

              case QUERY_DASHBOARD_TYPES.ACTIVE_HOURS: 
             return(
              {
                "aggs": {
                  "2": {
                    "date_histogram": {
                      "field": "Datetime",
                      "fixed_interval": "1h",
                      "time_zone": "Asia/Calcutta",
                      "min_doc_count": 1
                    },
                    "aggs": {
                      "3": {
                        "terms": {
                          "field": "Direction.keyword",
                          "order": {
                            "_count": "desc"
                          },
                          "size": 5
                        }
                      }
                    }
                  }
                },
                "size": 0,
                "stored_fields": [
                  "*"
                ],
                "script_fields": {},
                "docvalue_fields": [
                  {
                    "field": "Datetime",
                    "format": "date_time"
                  },
                  {
                    "field": "createdAt",
                    "format": "date_time"
                  },
                  {
                    "field": "updatedAt",
                    "format": "date_time"
                  }
                ],
                "_source": {
                  "excludes": []
                },
                "query": {
                  "bool": {
                    "must": [],
                    "filter": [
                      {
                        "match_all": {}
                      },
                      {
                        "bool": {
                          "should": contactMatchArray,
                          "minimum_should_match": 1
                        }
                      },
                      {
                        "range": {
                          "Datetime": {
                            "gte": new Date(startDate).toJSON(),
                            "lte": new Date(endDate).toJSON(),
                            "format": "strict_date_optional_time"
                          }
                        }
                      }
                    ],
                    "should": [],
                    "must_not": []
                  }
                }
              }
             )

              case QUERY_DASHBOARD_TYPES.ACTIVE_HOURS_CHATS: 
              return (
                {
                  "aggs": {
                    "2": {
                      "date_histogram": {
                        "field": "Datetime",
                        "fixed_interval": "1h",
                        "time_zone": "Asia/Calcutta",
                        "min_doc_count": 1
                      }
                    }
                  },
                  "size": 0,
                  "stored_fields": [
                    "*"
                  ],
                  "script_fields": {},
                  "docvalue_fields": [
                    {
                      "field": "Date",
                      "format": "date_time"
                    },
                    {
                      "field": "Datetime",
                      "format": "date_time"
                    },
                    {
                      "field": "createdAt",
                      "format": "date_time"
                    },
                    {
                      "field": "updatedAt",
                      "format": "date_time"
                    }
                  ],
                  "_source": {
                    "excludes": []
                  },
                  "query": {
                    "bool": {
                      "must": [],
                      "filter": [
                        {
                          "match_all": {}
                        },
                        {
                          "bool": {
                            "should": contactMatchArray,
                            "minimum_should_match": 1
                          }
                        },
                        {
                          "range": {
                            "Datetime": {
                              "gte": new Date(startDate).toJSON(),
                                "lte": new Date(endDate).toJSON(),
                              "format": "strict_date_optional_time"
                            }
                          }
                        }
                      ],
                      "should": [],
                      "must_not": []
                    }
                  }
                }
              )

            case QUERY_DASHBOARD_TYPES.TOTAL_COMPARISION_IN_DAYS:
              return (
                {
                  "aggs": {
                    "2": {
                      "terms": {
                        "field": "Direction.keyword",
                        "order": {
                          "_count": "desc"
                        },
                        "size": 5
                      },
                      "aggs": {
                        "3": {
                          "date_histogram": {
                            "field": "Datetime",
                            "calendar_interval": "1d",
                            "time_zone": "Asia/Calcutta"
                          }
                        }
                      }
                    }
                  },
                  "size": 0,
                  "stored_fields": [
                    "*"
                  ],
                  "script_fields": {},
                  "docvalue_fields": [
                    {
                      "field": "Datetime",
                      "format": "date_time"
                    },
                    {
                      "field": "createdAt",
                      "format": "date_time"
                    },
                    {
                      "field": "updatedAt",
                      "format": "date_time"
                    }
                  ],
                  "_source": {
                    "excludes": []
                  },
                  "query": {
                    "bool": {
                      "must": [],
                      "filter": [
                        {
                          "match_all": {}
                        },
                        {
                          "bool": {
                            "minimum_should_match": 1,
                            "should": contactMatchArray                            
                          }
                        },
                        {
                          "bool": {
                            "minimum_should_match": 1,
                            "should": [
                              {
                                "match_phrase": {
                                  "Direction.keyword": "2"
                                }
                              },
                              {
                                "match_phrase": {
                                  "Direction.keyword": "1"
                                }
                              },
                              {
                                "match_phrase": {
                                  "Direction.keyword": "3"
                                }
                              },
                              {
                                "match_phrase": {
                                  "Direction.keyword": "5"
                                }
                              }
                            ]
                          }
                        },
                        {
                          "range": {
                            "Datetime": {
                              "gte": new Date(startDate).toJSON(),
                                "lte": new Date(endDate).toJSON(),
                              "format": "strict_date_optional_time"
                            }
                          }
                        }
                      ],
                      "should": [],
                      "must_not": []
                    }
                  }
                }
              )

        default:
        break;
    }
}


const getContactsArray = (array) => {
  return new Promise((resolve, reject) => {
    let contactMatchArray = []
    array.forEach(contact => {
        contactMatchArray.push({
            "match_phrase": {
              "CreatedByUser.keyword": contact
            }
          })
    })
    resolve(contactMatchArray)
  })
}