import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import {store, persistor} from './store/store';
import {Amplify,Auth } from "aws-amplify";
import awsconfig from "./aws-exports";

// import i18n (needs to be bundled ;)) 
import './i18n';
// import { ErrorBoundary } from 'react-error-boundary';
// import ErrorBoundaryComp from './components/ErrorBoundaryComponent';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);


ReactDOM.render(
    <Provider store={store}>
    <BrowserRouter>
    <PersistGate persistor={persistor}>
    {/* <ErrorBoundary fallback={<ErrorBoundaryComp/>}> */}
    <App />
    {/* </ErrorBoundary> */}
    </PersistGate>
    </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();