import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SidebarContent from "./SidebarContent";
import Logo from '../../assets/eazybeTransparentLogo.png';
import eazybeLogo from "../../assets/icons/eazybe-logo-white.png";
import './navbar.scss';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserExistenceData } from "../../redux/auth/auth.selector";
import { getFixLengthString } from "../../util/commonfunctions";

const Navbar = (props) => {

  const [orgLogo, setOrgLogo] = useState({
    show: false,
    img: "",
    name: ""
  });

  const [sideNavExpanded, setSideNavExpanded] = useState(false);

  const toggleSideNav = (expand) => {
    let bodyEl = document.body;
    setSideNavExpanded(expand); // Directly set the value
    if (expand) {
      bodyEl.classList.remove("vertical-collpsed");
    } else {
      bodyEl.classList.add("vertical-collpsed");
    }
  };

  useEffect(() => {
    let orgCode = localStorage.getItem("currentOrgCode");
    if (orgCode) {
      fetchOrganizationData(orgCode);
    }
  }, []);

  const fetchOrganizationData = (orgCode) => {
    fetch(`https://eazybe.com/api/v1/whatzapp/getOrganizationDetails?organization_code=${orgCode}`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) throw new Error("Something Went Wrong!");
        return res.json();
      })
      .then((resData) => {
        if (resData?.type === true) {
          setOrgLogo({
            show: true,
            img: resData.data[0].org_logo,
            name: resData.data[0].org_name
          });
        }
      })
      .catch((err) => console.log(err));
  };
  
  const [showNavbar, setShowNavbar] = useState(true);
  useEffect(() => {
    const getItem = localStorage.getItem('pathParams'); 
    if (getItem) {
      const getItemData = JSON.parse(getItem);
      console.log('getItem', getItemData);
      const showDashboardOnly = getItemData.showDashboardOnly === 'true';

      if (showDashboardOnly) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
    }
  }, []);

  if (!showNavbar) {
    return null; 
  }

  return (
    <div 
      className={`vertical-menu ${sideNavExpanded ? 'expanded' : 'collapsed'}`} 
      onMouseEnter={() => toggleSideNav(true)} 
      onMouseLeave={() => toggleSideNav(false)}
    >
      <div className="navbar-brand-box">
        <div className="orgInfo">
          <Link to="/" className="logo logo-dark">
            <span className="logo-lg">
              <img src={orgLogo.show && (orgLogo.img ? orgLogo.img : eazybeLogo)} alt="Org Logo" height="30" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={Logo} alt="Logo" height="22" />
            </span>
            <span className="logo-lg">
              <img src={Logo} alt="Logo" height="19" />
            </span>
          </Link>
          <p className="org-name">
            {props.userExistence.data?.callyzer_user_mappings?.length ? 
              (props.userExistence.data?.callyzer_user_mappings[0]?.is_default_org ? 
                getFixLengthString(orgLogo.name, 10) : 
                getFixLengthString(props.userExistence.data.name, 10)
              ) : ""
            }
          </p>
        </div>
      </div>

      <div data-simplebar className="h-90">
        <SidebarContent 
          sideNavExpanded={sideNavExpanded} 
          setSideNavExpanded={setSideNavExpanded} 
        />
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userExistence: selectUserExistenceData,
});

export default connect(mapStateToProps)(React.memo(Navbar));
