import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import timesSquareIcon from '../../../assets/icons/times-square-Bold.svg'

import styles from './styles.module.scss';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectPlanList, selectTeamAddonList } from "../../../redux/auth/auth.selector";
import getSymbolFromCurrency from "currency-symbol-map";
import axios from "axios";
import { getTeamAddonList } from "../../../redux/auth/auth.action";
import { useTranslation } from "react-i18next";

const GroupedButtons = ({ activeButton, handleClick }) => {
  return (
    <div className={styles.groupedButtonsContainer}>
      <button
        value="INDIVIDUAL"
        onClick={handleClick}
        className={activeButton === "INDIVIDUAL" ? styles.activeBtn : ''}
      >For Individuals</button>
      <button
        value="TEAMS"
        onClick={handleClick}
        className={activeButton === "TEAMS" ? styles.activeBtn : ''}
      >For Teams</button>
    </div>
  )
}

const PeriodToggler = ({ billedType, setBilledType }) => {
  const [toggle, setToggle] = useState(billedType);

  function handleClickToggle() {
    if (toggle === "MONTHLY") {
      setToggle("ANNUALLY")
      setBilledType("ANNUALLY")
    } else {
      setToggle("MONTHLY")
      setBilledType("MONTHLY")
    }
  }

  return (
    <div className={`${styles.toggleContainer} pb-0-5`}>
      <h6
        className={toggle === "MONTHLY" ? styles.activeToggle : ''}
        onClick={toggle === "ANNUALLY" ? handleClickToggle : null}
      >Billed Monthly</h6>
      <div
        onClick={handleClickToggle}
        className={styles.toggleBoundary}
        style={toggle === "MONTHLY" ? { justifyContent: "flex-start" } : { justifyContent: "flex-end" }}
      >
        <div className={styles.toggleDot} />
      </div>
      <h6
        className={toggle === "ANNUALLY" ? styles.activeToggle : ''}
        onClick={toggle === "MONTHLY" ? handleClickToggle : null}
      >Billed Annually</h6>
    </div>
  )
}

const ToggleButton = ({ setAddon }) => {
  const [active, setActive] = useState(false);

  function onClickHandler() {
    setActive(prevState => !prevState);
    setAddon(prevState => !prevState);
  }

  return (
    <div
      className={styles.toggleButtonOuter}
      onClick={onClickHandler}
      style={active ? { justifyContent: "flex-end" } : { justifyContent: "flex-start" }}
    >
      <div className={styles.toggleButtonInner} />
    </div>
  )
}

const PlanCard = ({ type, name, price, features, billedType, selectedCard, setSelectedCard, userCurrencyCode, userCurrencySymbol, selectedPlanId, setActiveMainSection, setIsCallsAddonActive, setIsWhatsappAddonActive, setIsAiAddonActive, fetchedTeamAddonList, handleClose }) => {
  const [multiplicationFactor, setMultiplicationFactor] = useState();
  const [planIdConstant, setPlanIdConstant] = useState();
  const [usdToUserCurrencyExchangeRate, setUsdToUserCurrencyExchangeRate] = useState(1);

  const [callsAddon, setCallsAddon] = useState(false);
  const [whatsappAddon, setWhatsappAddon] = useState(false);
  const [aiAddon, setAiAddon] = useState(false);

  const getLocalizedPlanAmount = () => {
    fetch(
      `https://eazybe.com/api/v1/whatzapp/getLocalizedCurrency?user_currency=${userCurrencyCode}`,
      { method: "POST" }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("localized", res);
        if (res.status) {
          setMultiplicationFactor(res.message.multiplication_factor);
          setPlanIdConstant(res.message.planid_constant);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserCurrencyExchangeRate = () => {
    return new Promise((resolve, reject) => {
      fetch("https://eazybe.com/api/v1/whatzapp/exchangeRateService")
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res?.conversion_rates) {
            console.log("user currency code", userCurrencySymbol);
            let exchangeRate
            if (userCurrencyCode.toUpperCase() === "INR") {
              exchangeRate = Math.ceil(
                res.conversion_rates[userCurrencyCode]
              );
            } else {
              exchangeRate = Math.ceil(
                res.conversion_rates["USD"]
              );
            }
            console.log("exchangeRate", exchangeRate);
            setUsdToUserCurrencyExchangeRate(exchangeRate);
          }
          resolve(res.conversion_rates);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const createCheckoutSession = () => {
    let body = {
      workspace_id: localStorage.getItem("workspaceId"),
      plan_id: price === 0 ? 1 : planIdConstant ? planIdConstant + selectedPlanId : selectedPlanId,
      quantity: 1,
    };
    axios.post(`https://eazybe.com/api/v1/whatzapp/createorgcheckoutsession`,
      body
    )
      .then((res) => {
        console.log("createCheckoutSession", res);
        window.open(res.data.url, '_blank');

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserBasedPrice = () => {
    let amountToBeAdded = 0;
    if (callsAddon) {
      if (billedType === "MONTHLY") {
        if (userCurrencyCode.toUpperCase() === "INR") {
          amountToBeAdded += fetchedTeamAddonList[0].inr
        } else {
          amountToBeAdded += fetchedTeamAddonList[0].usd
        }
      } else {
        if (userCurrencyCode.toUpperCase() === "INR") {
          amountToBeAdded += fetchedTeamAddonList[0].annual_inr
        } else {
          amountToBeAdded += fetchedTeamAddonList[0].annual_usd
        }
      }
    }
    if (whatsappAddon) {
      if (billedType === "MONTHLY") {
        if (userCurrencyCode.toUpperCase() === "INR") {
          amountToBeAdded += fetchedTeamAddonList[1].inr
        } else {
          amountToBeAdded += fetchedTeamAddonList[1].usd
        }
      } else {
        if (userCurrencyCode.toUpperCase() === "INR") {
          amountToBeAdded += fetchedTeamAddonList[1].annual_inr
        } else {
          amountToBeAdded += fetchedTeamAddonList[1].annual_usd
        }
      }
    }
    if (aiAddon) {
      if (billedType === "MONTHLY") {
        if (userCurrencyCode.toUpperCase() === "INR") {
          amountToBeAdded += 280
        } else {
          amountToBeAdded += 7
        }
      } else {
        if (userCurrencyCode.toUpperCase() === "INR") {
          amountToBeAdded += 200
        } else {
          amountToBeAdded += 5
        }
      }
    }
    return multiplicationFactor ? (price * multiplicationFactor) + amountToBeAdded : (price * usdToUserCurrencyExchangeRate) + amountToBeAdded
  }

  function handleClick() {
    setSelectedCard(name);
  }

  function handleBtnClick() {
    if (type === "Teams") {
      setIsCallsAddonActive(callsAddon);
      setIsWhatsappAddonActive(whatsappAddon);
      setIsAiAddonActive(aiAddon);
      setActiveMainSection(1);
    } else {
      createCheckoutSession();
    }
  }

  function getPriceWithSymbol(addonType) {
    let priceWithSymbol;
    if (addonType === "calls") {
      if (billedType === "MONTHLY") {
        if (userCurrencyCode.toUpperCase() === "INR") {
          priceWithSymbol = `${userCurrencySymbol}${fetchedTeamAddonList[0].inr}`
        } else {
          priceWithSymbol = `$${fetchedTeamAddonList[0].usd}`
        }
      } else {
        if (userCurrencyCode.toUpperCase() === "INR") {
          priceWithSymbol = `${userCurrencySymbol}${fetchedTeamAddonList[0].annual_inr}`
        } else {
          priceWithSymbol = `$${fetchedTeamAddonList[0].annual_usd}`
        }
      }
    } else if (addonType === "whatsapp") {
      if (billedType === "MONTHLY") {
        if (userCurrencyCode.toUpperCase() === "INR") {
          priceWithSymbol = `${userCurrencySymbol}${fetchedTeamAddonList[1].inr}`
        } else {
          priceWithSymbol = `$${fetchedTeamAddonList[1].usd}`
        }
      } else {
        if (userCurrencyCode.toUpperCase() === "INR") {
          priceWithSymbol = `${userCurrencySymbol}${fetchedTeamAddonList[1].annual_inr}`
        } else {
          priceWithSymbol = `$${fetchedTeamAddonList[1].annual_usd}`
        }
      }
    } else if (addonType === "ai") {
      if (billedType === "MONTHLY") {
        if (userCurrencyCode.toUpperCase() === "INR") {
          priceWithSymbol = `${userCurrencySymbol}${280}`
        } else {
          priceWithSymbol = `$${7}`
        }
      } else {
        if (userCurrencyCode.toUpperCase() === "INR") {
          priceWithSymbol = `${userCurrencySymbol}${200}`
        } else {
          priceWithSymbol = `$${5}`
        }
      }
    }
    return priceWithSymbol
  }

  useEffect(() => {
    getLocalizedPlanAmount();
    getUserCurrencyExchangeRate();
  })
const {t} = useTranslation()
  return (
    <div className={true ? `${styles.planContainer} ${styles.darkPlan}` : styles.planContainer} onClick={handleClick}>
      {billedType === "ANNUALLY" && price !== 0 ? <div className={styles.discount}>23% Off</div> : null}
      <div className={styles.info}>
        <p className={styles.type}>{type}</p>
        <p className={styles.name}>{name}</p>
        <p className={styles.price}>{price === 0 ? "Free forever" : `${userCurrencyCode.toUpperCase() === "INR" ? userCurrencySymbol : "$"}${getUserBasedPrice()} /employee/month`}</p>
        <ul>
          {features.map(feature => {
            return <li>{feature}</li>
          })}
        </ul>
        {type === "Teams" ? (
          <div className={styles.addonsContainer}>
            {/* <div className={styles.addon}>
              <ToggleButton setAddon={setCallsAddon} />
              Backup & sync calls - {getPriceWithSymbol("calls")}
            </div>
            <div className={styles.addon}>
              <ToggleButton setAddon={setWhatsappAddon} />
              Backup & sync Whatsapp - {getPriceWithSymbol("whatsapp")}
            </div> */}
            <div className={styles.addon}>
              <ToggleButton setAddon={setAiAddon} />
              Eazybe AI Reply - {getPriceWithSymbol("ai")}
            </div>
          </div>
        ) : null}
      </div>
      <button onClick={price === 0 ? handleClose : handleBtnClick}>{price === 0 ? "Start for free" :
        t('Upgrade_now.message')}
      </button>
    </div>
  )
}

const UpgradePlansPopup = ({ openPopup, handleClose, setActiveMainSection, fetchedPlanList, setIsCallsAddonActive, setIsWhatsappAddonActive, setIsAiAddonActive, fetchTeamAddonList, fetchedTeamAddonList }) => {
  const [billedType, setBilledType] = useState("MONTHLY");
  const [selectedCard, setSelectedCard] = useState();
  const [activeButton, setActiveButton] = useState(localStorage.getItem("currentOrgId") ? "TEAMS" : "INDIVIDUAL");
  const [individualMonthlyPlans, setIndividualMonthlyPlans] = useState();
  const [individualYearlyPlans, setIndividualYearlyPlans] = useState();
  const [teamsMonthlyPlans, setTeamsMonthlyPlans] = useState();
  const [teamsYearlyPlans, setTeamsYearlyPlans] = useState();
  const [userCurrencyCode, setUserCurrencyCode] = useState("");

  useEffect(() => {
    if (!fetchedTeamAddonList) {
      fetchTeamAddonList();
    }
  })

  const getUserCurrencyCode = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
  
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
  
      const data = await response.json();
  
      console.log(data.currency);
  
      if (data.currency) {
        setUserCurrencyCode(data.currency);
        return data.currency; // Return just the currency code if that’s all you need.
      } else {
        throw new Error("Currency data is missing in API response");
      }
    } catch (err) {
      console.error("Error in getUserCurrencyCode:", err);
      return null; // Return null or a default value if the API fails
    }
  };
  
  useEffect(() => {
    getUserCurrencyCode();
  }, [])

  function handleClick(event) {
    setActiveButton(event.target.value)
  }

  useEffect(() => {
    const individualMonthlyArr = [];
    const individualYearlyArr = [];
    const teamsMonthlyArr = [];
    const teamsYearlyArr = [];
    fetchedPlanList?.plan_list.forEach(plan => {
      if (plan.plan_type === "self") {
        if (plan.isMonthly) {
          individualMonthlyArr.push(plan);
        } else {
          if (plan.plan_name !== "LTD") {
            individualYearlyArr.push(plan);
          }
        }
      } else {
        if (plan.isMonthly) {
          teamsMonthlyArr.push(plan);
        } else {
          teamsYearlyArr.push(plan);
        }
      }
    });
    if (individualMonthlyArr && individualYearlyArr && teamsMonthlyArr && teamsYearlyArr) {
      setIndividualMonthlyPlans(individualMonthlyArr);
      setIndividualYearlyPlans(individualYearlyArr);
      setTeamsMonthlyPlans(teamsMonthlyArr);
      setTeamsYearlyPlans(teamsYearlyArr);
    }
    console.log(fetchedPlanList)
  }, [fetchedPlanList])


  return (
    <Modal
      open={openPopup}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.popupContainer}>
        <header className={styles.header}>
          <h3>Choose the right plan</h3>
          <img
            src={timesSquareIcon}
            alt="Close Icon"
            onClick={handleClose}
          />
        </header>
        <p className="mt-0-5 mb-2">Flexible pricing options for Individuals and Teams. </p>
        {!localStorage.getItem("currentOrgId") ? (
          <GroupedButtons activeButton={activeButton} handleClick={handleClick} />
        ) : null}
        <PeriodToggler billedType={billedType} setBilledType={setBilledType} />
        {activeButton === "INDIVIDUAL" ? (
          <div className={`${styles.plansContainer} mt-2`}>
            {billedType === "MONTHLY" ? individualMonthlyPlans?.map(plan => (
              <PlanCard
                type={"Individual"}
                name={plan.plan_name}
                price={plan.amount}
                features={plan.offerings}
                billedType={billedType}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                userCurrencyCode={userCurrencyCode}
                userCurrencySymbol={getSymbolFromCurrency(userCurrencyCode)}
                selectedPlanId={plan.id}
                handleClose={handleClose}
              />
            )) : individualYearlyPlans?.map(plan => (
              <PlanCard
                type={"Individual"}
                name={plan.plan_name}
                price={plan.amount}
                features={plan.offerings}
                billedType={billedType}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                userCurrencyCode={userCurrencyCode}
                userCurrencySymbol={getSymbolFromCurrency(userCurrencyCode)}
                selectedPlanId={plan.id}
                handleClose={handleClose}
              />
            ))}
          </div>
        ) : (
          <div className={`${styles.plansContainer} mt-2`}>
            {billedType === "MONTHLY" ? teamsMonthlyPlans?.map(plan => (
              <PlanCard
                type={"Teams"}
                name={plan.plan_name}
                price={plan.amount}
                features={plan.offerings}
                billedType={billedType}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                userCurrencyCode={userCurrencyCode}
                userCurrencySymbol={getSymbolFromCurrency(userCurrencyCode)}
                selectedPlanId={plan.id}
                setActiveMainSection={setActiveMainSection}
                setIsCallsAddonActive={setIsCallsAddonActive}
                setIsWhatsappAddonActive={setIsWhatsappAddonActive}
                setIsAiAddonActive={setIsAiAddonActive}
                fetchedTeamAddonList={fetchedTeamAddonList}
              />
            )) : teamsYearlyPlans?.map(plan => (
              <PlanCard
                type={"Teams"}
                name={plan.plan_name}
                price={plan.amount}
                features={plan.offerings}
                billedType={billedType}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                userCurrencyCode={userCurrencyCode}
                userCurrencySymbol={getSymbolFromCurrency(userCurrencyCode)}
                selectedPlanId={plan.id}
                setActiveMainSection={setActiveMainSection}
                setIsCallsAddonActive={setIsCallsAddonActive}
                setIsWhatsappAddonActive={setIsWhatsappAddonActive}
                setIsAiAddonActive={setIsAiAddonActive}
                fetchedTeamAddonList={fetchedTeamAddonList}
              />
            ))}
          </div>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  fetchedPlanList: selectPlanList,
  fetchedTeamAddonList: selectTeamAddonList
});

const mapDispatchToProps = (dispatch) => ({
  fetchTeamAddonList: () => dispatch(getTeamAddonList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlansPopup);