/*global chrome*/
import React, { useState,  useEffect } from "react";
import "./googlesheetintegrationpage.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Container,
  Row,
} from "reactstrap";
import { gapi } from "gapi-script";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as XLSX from "xlsx/xlsx.mjs";
import { Input, InputGroup } from "rsuite";
/* load 'fs' for readFile and writeFile support */
import * as fs from "fs";
import showToast from "../../../../components/Toasts/ShowToast";
import axios from "axios";
import GoogleSheetNotConnected from "./GoogleSheetNotConnected/GoogleSheetNotConnected";
import { checkForCrmiKeyInAuth, sendDataToWhatsapp } from "../../../../util/helper";
import { EXTENSION_ID_PRODUCTION } from "../../../../util/api";
XLSX.set_fs(fs);

let CLIENT_ID_EAZYBE =
  "917967494072-ns8tmfbg91et459jsm54ne5uke8lapav.apps.googleusercontent.com";
let SECRET_KEY_EAZYBE = "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";
let EAZYBE_REDIRECT_URI = "https://workspace.eazybe.com";





/**
 * Style for our popup modal
 */
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "18rem",
  // height: "50vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "10px",
};



/**
 * Scopes to be requested to retrieve files from drive and then get sheets info from spreadsheet api
 */

const SCOPES_NEW =
  // "https://www.googleapis.com/auth/spreadsheets";
  "https://www.googleapis.com/auth/drive.file";


const styles = {
  marginBottom: 10,
};

/**
 *
 * @param {SetMainActiveSection} -Changes current active section
 * @returns -Google Sheet Integration Page Component
 */

const GoogleSheetIntegrationPage = ({ setMainActiveSection, connectIntegration, extensionIdToSendMessageTo }) => {
  const [sheetFiles, setSheetFiles] = useState([]);
  const [googleSheetConnected, setGoogleSheetConnected] = useState(false);

  //   /////

  /**
   * Variable to set our current active screen
   */
  const [activeSection, setActiveSection] = useState(0);

  useEffect(()=> {
    console.log("GooglesheetIntegratinPage connectIntegration ", connectIntegration);
    if(connectIntegration){
      // setTimeout(()=> {
      //   execute();
      // }, 2000);
    }

  }, [connectIntegration])

  const CustomInputGroup = ({ onChange, placeholder, ...props }) => (
    <InputGroup {...props} onChange={onChange} style={styles}>
      <Input placeholder={placeholder} />
      {/* <InputGroup.Addon>
        <SearchIcon />
      </InputGroup.Addon> */}
    </InputGroup>
  );

  //// Modal variables

  const [openPopup, setOpenPopup] = React.useState(false);
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const [refreshToken, setRefreshToken] = useState();
  const [accessToken, setAccessToken] = useState();
  const [expiresIn, setExpiresIn] = useState();

  useEffect(() => {
    checkIfGoogleSheetIntegrated();
  }, []);



  const getRefreshToken = (authCode, tempAccessToken, tempExpiresIn) => {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tempAccessToken}`,
      };

      axios
        .post(
          "https://oauth2.googleapis.com/token",

          {
            code: authCode,
            client_id: CLIENT_ID_EAZYBE,
            client_secret: SECRET_KEY_EAZYBE,
            // client_id: CLIENT_ID_TEST,
            // client_secret: SECRET_KEY_TEST,
            // redirect_uri: LOCAL_HOST_REDIRECT_URI,
            // redirect_uri: NETLIFY_REDIRECT_URI,
            redirect_uri: EAZYBE_REDIRECT_URI,
            grant_type: "authorization_code",
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("getRefreshToken response", response);
          const refreshToken = response.data.refresh_token;
          if (refreshToken) {
            resolve(refreshToken);
          } else {
            resolve();
          }
          // Do something with the refresh token
        });
    });
  };

  useEffect(() => {
    console.log("accessToken  changed ", accessToken);
  }, [accessToken]);

  function authenticateTrial() {
    return gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(
        async function (responseAuthenticate) {
          console.log("responseAuthenticate", responseAuthenticate);
          setAccessToken(responseAuthenticate?.xc.access_token);
          let tempAccessToken = responseAuthenticate?.xc.access_token;
          setExpiresIn(responseAuthenticate?.xc?.expires_in);
          let tempExpiresIn = responseAuthenticate?.xc?.expires_in;

          // .getAuthInstance()
          let res = await gapi.auth2.getAuthInstance().grantOfflineAccess();
          console.log("grantOfflineAccess res", res);
          let authCode = res?.code;
          setTimeout(async () => {
            let refresh_token;
        sendDataToWhatsapp({
          authenticateTrial : true,
          authCode : authCode,
          tempAccessToken : tempAccessToken,
          tempExpiresIn  : tempExpiresIn,
          workspaceId : localStorage?.getItem("workspaceId"),
        })

            refresh_token = await getRefreshToken(
              authCode,
              tempAccessToken,
              tempExpiresIn
            );
            setRefreshToken(refresh_token);
            handleOpenPopup();
          }, 200);
          console.log("Sign-in successful");
        },
        function (err) {
          console.error("Error signing in", err);
        }
      );
  }
  function loadClient(response) {
    gapi.client.setApiKey("");
    return gapi.client
      .load("https://content.googleapis.com/discovery/v1/apis/drive/v3/rest")
      .then(
        function () {
          console.log("logged in successfully");
        },
        function (err) {
          console.error("Error loading GAPI client for API", err);
        }
      );
  }

  /**
   * Make sure the client is loaded and sign-in is complete before calling this method.
   */
  async function execute() {
    // await authenticate().then(loadClient);
     sendDataToWhatsapp({
      execute : true,
          workspaceId : localStorage?.getItem("workspaceId"),
        })
    await authenticateTrial().then(loadClient);
  }


useEffect(()=> {
  gapi.load("client:auth2", function () {
    gapi.auth2.init({ client_id: CLIENT_ID_EAZYBE, scope: SCOPES_NEW });
  });
}, [])


  

  /**
   *
   * @returns Google Sheet Modal where we select the spreadsheet and subsheet
   */

  const GoogleSheetModal = () => {
    // console.log("driveFileList", driveFileList);


    const [selectedSpreadsheetId, setSelectedSpreadsheetId] = useState();
    const [selectedSpreadsheetName, setSelectedSpreadsheetName] = useState();
    const [selectedSubSheetName, setSelectedSubSheetName] = useState();
    const [labelName, setLabelName] = useState("Google Sheet");

    const onChangeLabelName = (e) => setLabelName(e.target.value);
    // const onChangeLabelName = (e) => console.log("onChangeLabelName", e.target.value);

    const saveSheetDetails = (tempSheetId, tempSubsheetName, tempSheetName) => {
      return new Promise((resolve, reject) => {

        console.log("tempSheetName: ", tempSheetName);
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const data = {
          workspace_id: localStorage.getItem("workspaceId"),
          sheet_id: tempSheetId,
          sub_sheet_name: tempSubsheetName,
          access_token: accessToken,
          expires_in: expiresIn,
          refresh_token: refreshToken,
          label_name: labelName ?? " Google Sheets",
          file_name: tempSheetName,
        }
        let raw = JSON.stringify(data);
        sendDataToWhatsapp(data)

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };
        fetch(`https://eazybe.com/api/v1/whatzapp/googlesheet`, requestOptions)
          .then((res) => res.json())
          .then(async (response) => {
            if (response.result) {
              // let id = "fpnlneiiaeclcckbcoldjhcpaofgdmfl";
              // let key = "GOGOLE_SHEET_CONNECTED";
              // sendMessageToChromeExtension(id, key);
              // sendMessageToChromeExtension();
              window.gtag("event", "Googlesheetintegrated");
        window.gtagAW("event", "Googlesheetintegrated");
              checkForCrmiKeyInAuth();
              checkIfGoogleSheetIntegrated();
              sendMessageToChromeExtension();
              showToast("Successfully linked google sheet");
              handleClosePopup();
            }
            else {

              showToast("Something went wrong, refersh once and please give google sheet's permission");
              handleClosePopup();
            }
            resolve(response);
          });
      });
    };

    useEffect(() => {
      console.log("selectedSpreadsheetId changed ", selectedSpreadsheetId);
    }, [selectedSpreadsheetId]);


    const onClickSyncBtn = async () => {
      // console.log("selectedSheetId ", selectedSpreadsheetId);
      // console.log("selectedSubSheetName ", selectedSubSheetName);
      await saveSheetDetails(
        selectedSpreadsheetId,
        selectedSubSheetName,
        selectedSpreadsheetName
      );
   
    };

    
    return (
      <Modal
        // hideBackdrop
        open={openPopup}
        onClose={handleClosePopup}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="google-sheet-modal"
      >
        <Box className="modal-container" sx={{ ...style }}>
          <Container className="files-container d-flex-col">
            <p className="mt-1 select-txt mb-1"> Label Name</p>
            <CustomInputGroup
              value={labelName}
              onChange={onChangeLabelName}
              size="sm"
              placeholder="Google Sheet"
            />
          </Container>

          <Row className="btns-container d-flex">
            <button className="cancel-btn" onClick={handleClosePopup}>
              Cancel
            </button>
            <button className="sync-btn" onClick={onClickSyncBtn}>
              Sync
            </button>
          </Row>
        </Box>
      </Modal>
      // </Backdrop>
    );
  };

  const checkIfGoogleSheetIntegrated = () => {
    fetch(
      `https://eazybe.com/api/v1/whatzapp/googlesheet?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("response : ", response);
        if (response.result) {
          // sendMessageToChromeExtension(true, 500);
          setGoogleSheetConnected(true);
        } else {
          // sendMessageToChromeExtension(false, 500);
          setGoogleSheetConnected(false);
        }
      });
  };

  const 
  sendMessageToChromeExtension = (
    status,
    time = 150,
    id = "clgficggccelgifppbcaepjdkklfcefd",
    key
  ) => {
    let EXTENSION_ID = "clgficggccelgifppbcaepjdkklfcefd";
    // let TEST_EXTENSION_ID = "fpnlneiiaeclcckbcoldjhcpaofgdmfl";
    setTimeout(() => {
      if (chrome?.runtime) {
        chrome.runtime.sendMessage(
          extensionIdToSendMessageTo,
          { key: key ?? "GOOGLE_SHEET_CONNECTED" },
          (response) => {
            console.log("response : ", response);
            if (!response?.success) {
            }
          }
        );
        chrome.runtime.sendMessage(
           EXTENSION_ID_PRODUCTION,
          { key: key ?? "GOOGLE_SHEET_CONNECTED" },
          (response) => {
            console.log("response : ", response);
            if (!response?.success) {
            }
          }
        );
      }
    }, time);
  };

  

 

  return (
    <div className="google-sheet-integration-page-container">
      <div className="navigation">
        <p onClick={() => setMainActiveSection(0)}>Integrations</p>
        <ArrowForwardIosIcon className="arrow-icon" />
        <p
          onClick={() => setActiveSection(0)}
          className={activeSection == 0 ? "active-link" : ""}
        >
          Google Sheets CRM Integration{" "}
        </p>
        {activeSection == 1 ? (
          <>
            <ArrowForwardIosIcon className="arrow-icon" />
            <p className={activeSection == 1 ? "active-link" : ""}>
              Configure{" "}
            </p>
          </>
        ) : null}
      </div>

      <GoogleSheetNotConnected
        googleSheetConnected={googleSheetConnected}
        setGoogleSheetConnected={setGoogleSheetConnected}
        onClickConnect={execute}
        checkIfGoogleSheetIntegrated={checkIfGoogleSheetIntegrated}
        sendMessageToChromeExtension={sendMessageToChromeExtension}
      />
      <GoogleSheetModal />
    </div>
  );
};

export default GoogleSheetIntegrationPage;
