/*global chrome*/
import React, { useEffect, useState } from "react";
import "./leadsquaredintegrationpage.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { EXTENSION_ID_PRODUCTION } from "../../../../util/api";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { saveDontShowUpgrade } from "../../../../redux/organization/organization.action";
import LeadsquaredModal from "./LeadsquaredModal/LeadsquaredModal";
import LeadsquaredNotConnected from "./LeadsquaredNotConnected/LeadsquaredNotConnected";
import { getAuthLeadsquared } from "../../../../redux/integrations/integrations.action";

let EXTENSION_ID = "clgficggccelgifppbcaepjdkklfcefd";
let EXTENSION_ID_TEST = "fpnlneiiaeclcckbcoldjhcpaofgdmfl";
let REDIRECT_URI = "https://workspace.eazybe.com/integrations?section=hubspot";

const LeadsquaredIntegrationPage = ({
  setMainSectionIntegrations,
  connectIntegration,
  extensionIdToSendMessageTo,
  saveDontShowUpgradeStatus,
}) => {
  let LEADSQUARED_SECTION_IDS = {
    ALREADY_CONNECTED: "ALREADY_CONNECTED",
    NOT_CONNECTED: "NOT_CONNECTED",
    GO_BACK_TO_EXTENSION: "GO_BACK_TO_EXTENSION",
    HUBSPOT_PAGE1: "HUBSPOT_PAGE1",
  };

  const [activeSectionHubspot, setActiveSectionHubspot] = useState(
    LEADSQUARED_SECTION_IDS.NOT_CONNECTED
  );
  // const [activeSectionHubspot, setActiveSectionHubspot] = useState(LEADSQUARED_SECTION_IDS.ALREADY_CONNECTED);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("queryParams in hubspot section", queryParams);
    let tempCode = queryParams?.get("code");
    console.log("tempCode : ", tempCode);
    checkIfLeadsquaredIntegrated();
    console.log(
      "connectIntegration in leadsquaredIntegration",
      connectIntegration
    );
    /// Redirect to redirect_uri
    if (connectIntegration) {
      connectLeadsquared();
    }
  }, [connectIntegration]);

  const checkIfLeadsquaredIntegrated = async () => {
    const leadsquaredAuthRes = await getAuthLeadsquared();
    if (leadsquaredAuthRes?.status) {
      setLeadsquaredConnected(true);
      saveDontShowUpgradeStatus(true);
      // sendMessageToChromeExtension(true, 500);setLeadsquaredConnected
      setActiveSectionHubspot(LEADSQUARED_SECTION_IDS.ALREADY_CONNECTED);
    } else {
      setLeadsquaredConnected(false);
      // sendMessageToChromeExtension(false, 500);
      setActiveSectionHubspot(LEADSQUARED_SECTION_IDS.NOT_CONNECTED);
    }
  };

  const sendMessageToChromeExtension = (
    status,
    time = 10,
    id = "clgficggccelgifppbcaepjdkklfcefd",
    key
  ) => {
    console.log("sendMessageToChromeExtension called");
    setTimeout(() => {
      if (chrome?.runtime) {
        chrome.runtime.sendMessage(
          EXTENSION_ID_PRODUCTION,
          { key: key ?? "LEADSQUARED_CONNECTED" },
          (response) => {
            console.log("sendMessageToChromeExtension  response : ", response);
            if (!response?.success) {
            }
          }
        );

        chrome.runtime.sendMessage(
          extensionIdToSendMessageTo,
          { key: key ?? "LEADSQUARED_CONNECTED" },
          (response) => {
            console.log("sendMessageToChromeExtension  response : ", response);
            if (!response?.success) {
            }
          }
        );
      }
    }, time);
  };
  const [leadsquaredConnected, setLeadsquaredConnected] = useState(false);
  const [leadsquaredModalActive, setLeadsquaredModalActive] = useState(false);

  const enableLeadsquaredModal = () => setLeadsquaredModalActive(true);
  const disableLeadsquaredModal = () => setLeadsquaredModalActive(false);

  const connectLeadsquared = () => {
    enableLeadsquaredModal();
  };

  return (
    <div>
      <ArrowBackIcon
        onClick={setMainSectionIntegrations}
        className="mt-1 cursor-pointer"
      />
      <LeadsquaredNotConnected
        leadsquaredConnected={leadsquaredConnected}
        setLeadsquaredConnected={setLeadsquaredConnected}
        onClickConnect={connectLeadsquared}
        checkIfBitrixConnected={checkIfLeadsquaredIntegrated}
        sendMessageToChromeExtension={sendMessageToChromeExtension}
      />

      <LeadsquaredModal
        open={leadsquaredModalActive}
        handleClose={disableLeadsquaredModal}
        setLeadsquaredConnected={setLeadsquaredConnected}
        checkIfLeadsquaredIntegrated={checkIfLeadsquaredIntegrated}
        sendMessageToChromeExtension={sendMessageToChromeExtension}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
  saveDontShowUpgradeStatus: (status) => dispatch(saveDontShowUpgrade(status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsquaredIntegrationPage);
