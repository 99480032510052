import React, { useState } from 'react';
import { Autocomplete, TextField, Checkbox, Typography, FormControl } from '@mui/material';

const MuiMultiSelect = ({ label, options, visibleField, setOptions }) => {
    const handleChange = (event, value) => {
        const updatedOptions = options.map(option => ({
            ...option,
            isSelected: value.map(v => v.id).includes(option.id),
        }));

        setOptions(updatedOptions);
    };

    const handleFieldChange = (event, value, item) => {
        item.selectedField.splice(0, item.selectedField.length);
        value.forEach(option => item.selectedField.push(option));
        setOptions([...options]); // Trigger rerender by updating the state
    };

    return (
        <>
            <div>
                <Autocomplete
                    multiple
                    options={options}
                    getOptionLabel={(option) => option[visibleField]}
                    value={options.filter(option => option.isSelected)}
                    onChange={handleChange}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option[visibleField]}
                        </li>
                    )}
                    style={{ width: 350, margin: '8px' }}
                    renderInput={(params) => (
                        <TextField {...params} label={label} placeholder="Select options" />
                    )}
                />
            </div>

            {options.map(item => (
                item.isSelected &&
                <div key={item.id}>
                    <Typography paragraph gutterBottom sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "16px", fontWeight: "600" }}>{`Phone number Field Title for ${item.name}`}</Typography>
                    <FormControl sx={{ m: 1, width: "350px" }}>
                        <Autocomplete
                            multiple
                            options={item.fields}
                            getOptionLabel={(option) => option[visibleField]}
                            value={item.selectedField}
                            onChange={(event, value) => handleFieldChange(event, value, item)}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option[visibleField]}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label={`Phone number Field Title for ${item.name}`} placeholder="Select options" />
                            )}
                        />
                    </FormControl>
                </div>
            ))}
        </>
    );
}

export default MuiMultiSelect;
