import React from "react";
import "./webhooksetupguidesetion.scss";
import WebhookSaveEndpointBtn from "./WebhookSaveEndpointBtn";
import showToast from "../../../../../components/Toasts/ShowToast";
import JSONPreview from "./WebhookSaveEndpointBtn/JsonPreviewComp/JsonPreviewComp";

// const exampleObj = {
//   MessageId: "FD00EEE8D40B5DCC2A1AEED3D57AC2F9",
//   Message: "Test incoming msg 1",
//   Chatid: "918285501@c.us",
//   File: null,
//   FileName: null,
//   Ack: 1,
//   Datetime: 1690185735000,
//   Date: "2023-07-24T08:02:15.000Z",
//   Direction: "INCOMING",
//   SentById: "918285501@c.us",
//   ChannelName: "hh",
//   ChannelId: "1:1",
//   SentByNumber: "918285501",
//   CreatedByUser: "919958731",
// };

const exampleObj = {
  "918285501@c.us": [
      {
          "MessageId": "3EB03A5F02D925B79B20C9",
          "Message": "hi",
          "Chatid": "918285501@c.us",
          "File": null,
          "FileName": null,
          "Ack": 3,
          "Datetime": 1716973374000,
          "Date": "2024-05-29T09:02:54.000Z",
          "Direction": "OUTGOING",
          "SentById": "918285501@c.us",
          "ChannelName": "Anirudh",
          "ChannelId": "1:1",
          "SentByNumber": "918285501",
          "sentByUserId": "918285501@c.us",
          "CreatedByUser": "919958731",
          "senderName": "Anirudh"
      }
  ]
};

const WebhookSetupGuideSection = ({
  onClickSaveEndPoint,
  webhookIntegrationData,
}) => {
  // const onClickSaveEndpoint1 = (value) => {
  //   if (value) {
  //     onClickSaveEndPoint("incoming", value);
  //   }
  // };
  // const onClickSaveEndpoint2 = (value) => {
  //   if (value) {
  //     onClickSaveEndPoint("outgoing", value);
  //   }
  // };
  const onClickSaveCommonEndpoint = (value) => {
    if (value) {
      onClickSaveEndPoint("duplex_webhook", value);
    }
  };
  const onClickSaveEndpoint3 = (value) => {
    if (value) {
      onClickSaveEndPoint("auth_token", value);
    }
  };
  return (
    <div className="webhook-setup-guide-section">
      <h5>Setup Guide</h5>
      <div className="guide-con d-flex-col mt-1">
        <div className="guide-info d-flex-col">
          <p>
            In this guide, you'll connect WhatsApp to your destination using
            Eazybe custom webhooks.
          </p>
          <p>
            You can use the same webhook for the 2 different event triggers, or
            set up separate webhooks for each event trigger.
          </p>
        </div>

        <div className="steps-con d-flex-col">
          <h6>Setup Webhook</h6>
          <ol className="step-items">
            <li>Create a webhook endpoint from your desired destination.</li>
            <li>Paste the webhook url in your desired trigger below.</li>
            <li>
              Click on "Save" button. Then a sample request will be sent to your
              catch hook.
            </li>
          </ol>
        </div>

        <div className="buttons-con d-flex-col">
          {/* <WebhookSaveEndpointBtn
            title={"Trigger actions when receive WhatsApp messages"}
            currentValue={webhookIntegrationData?.data?.incoming ?? ""}
            onClickSave={onClickSaveEndpoint1}
            placeholder="Webhook URL"
          />
          <WebhookSaveEndpointBtn
            title={"Trigger actions when send WhatsApp messages"}
            currentValue={webhookIntegrationData?.data?.outgoing ?? ""}
            onClickSave={onClickSaveEndpoint2}
            placeholder="Webhook URL"

          /> */}

          <WebhookSaveEndpointBtn
            title={"Trigger actions for WhatsApp messages (both send and receive)"}
            currentValue={webhookIntegrationData?.data?.duplex_webhook ?? ""}
            onClickSave={onClickSaveCommonEndpoint}
            placeholder="Webhook URL"
          />
          <WebhookSaveEndpointBtn
            title={"Add auth token here (Optional)"}
            currentValue={webhookIntegrationData?.data?.auth_token ?? ""}
            onClickSave={onClickSaveEndpoint3}
            placeholder="Auth token"
          />
        </div>

        <div className="payload-con">
          <p className="title">
            The payload from the webhook that you can use to set up actions is
            shown below:
          </p>
          <JSONPreview data={exampleObj} />

        </div>
      </div>
    </div>
  );
};

export default WebhookSetupGuideSection;

