import React from "react";
import "./webhookoverviewsection.scss";
import whatsappIcon from "../../../../../assets/icons/whatsapp-icon.png";
import webhookIcon from "../../../../../assets/icons/webhook-icon.png";
import { useTranslation } from "react-i18next";

const WebhookOverviewSection = ({ description, example, featuresArr }) => {
  const { t } = useTranslation();
  return (
    <div className="webhook-overview-section">
      <h5>
        {t("Overview.message")}
      </h5>

      <div className="features-con d-flex-col mt-1">
        <p className="overview">{description}</p>
        <p className="example">{example}</p>
        <div className="features-items d-flex-col">
          {featuresArr.map((feature, indexOfItem) => (
            <div key={indexOfItem} className="feature-item d-flex">
              <div className="icons-con d-flex">
                <img src={whatsappIcon} />
                <img src={webhookIcon} />
              </div>
              <p className="feature">{feature}</p>
            </div>
          ))}
        </div>
      </div>



    </div>
  );
};

export default WebhookOverviewSection;
