import { createSelector } from "reselect";

const auth = (state) => state.auth;

export const selectUserData = createSelector([auth], (auth) => auth.userData);

export const selectUserExistenceData = createSelector(
  [auth],
  (auth) => auth.userExistenceData
);

export const selectUserCreditHistory = createSelector(
  [auth],
  (auth) => auth.userCreditHistory
);

export const selectOrgCreditHistory = createSelector(
  [auth],
  (auth) => auth.orgCreditHistory
);

export const selectPlanList = createSelector(
  [auth],
  (auth) => auth.getPlanList
);

export const selectUserProfileData = createSelector(
  [auth],
  (auth) => auth.userProfileData
);



export const selectUserTags = createSelector([auth], (auth) => auth.userTags);

export const selectTeamAddonList = createSelector(
  [auth],
  (auth) => auth.getTeamAddonList
);

export const selectIsAuth = createSelector([auth], (auth) => auth.isAuth);

export const selectUserAccessToken = createSelector([auth], (auth) => {
  const { accounts, active_account } = auth.userDataV2;
  console.log("selectUserAccessToken", { auth, accounts, active_account });
  if (
    !accounts ||
    !Array.isArray(accounts) ||
    !accounts.length ||
    !active_account
  ) {
    return null;
  }

  return (
    accounts.find((account) => account.email === active_account.email) || null
  );
});

/// Roles:
/// 0: Individual
/// 1: Admin
/// 2: Manager
/// 3: Employee

export const selectUserRoleId = createSelector([auth], (auth) => {
  let userMapping = auth.userExistenceData.data?.callyzer_user_mappings;
  if (userMapping.length) {
    let tempUserRoleId = 0;
    userMapping.forEach((item) => {
      if (item.is_default_org == 1) {
        tempUserRoleId = item.role_id;
      }
    });

    return tempUserRoleId;
  } else {
    return 0;
  }
});


export const selectUserDataV2 = createSelector(
    [auth],
    (auth) => auth.userDataV2
  );

  export const selectShowDashboardOnly = createSelector(
    [auth],
    (auth) => auth.showDashboardOnly
  );