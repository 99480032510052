import React from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from 'rsuite';

const AlertModal = ({open, handleClose, title, message, okText}) => {
  return (
    <Modal open={open} onClose={handleClose}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {message}
      {/* <Placeholder.Paragraph /> */}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={handleClose} appearance="primary">
        {okText ?? "ok"}
      </Button>
      
    </Modal.Footer>
  </Modal>
  )
}

export default AlertModal