import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './style.css';
import SecureDashboard from './sub-components/dashboard';

const AdminPanelSecure = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    // Replace this with your actual login logic
    if (['akshay@eazybe.com', 'shivam@eazybe.com'].includes(email) && password === 'securedbyvats') {
      setIsLoggedIn(true);
    } else {
      alert('Invalid login credentials');
    }
  };

  return (
    <div className="AdminPanelSecure">
      {!isLoggedIn ? (
        <div className="loginPageWrapper">
          <Container className="login-container">
            <Row className="justify-content-md-center">
              <Col>
                <h2 className="text-center">Login</h2>
                <Form onSubmit={handleLogin}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <button className='login-btn' type="submit" block>
                    Login
                  </button>
                </Form>

                <p>Don't have an account? Contact <b>Akshay Vats</b></p>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <SecureDashboard />
      )}
    </div>
  );
};

export default AdminPanelSecure;
