import React, { useEffect } from 'react';
import './accountitem.scss'

const AccountItem = ({icon, logo, text, active,setActive,org_id, subText}) => {
  useEffect(()=> {
    console.log("setActive", setActive);
  }, [])
  return (
    <div className="account-item-con d-flex align-item-center " id={org_id} onClick={setActive}>
        <img src={logo ? logo : icon} alt={text}/>
        <div className='d-flex-col ml-1'>

        <p className=' fw-500' id={org_id} > {text}</p>
       {subText ? <p className='user-email'>{subText}</p> : null}
        </div>
        {
            active &&
        <p className='currently-active'>Currently Active</p>
        }
    </div>
  )
}

export default AccountItem