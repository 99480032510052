import React, { useEffect, useState } from "react";
import "./webhooksaveendpointbtn.scss";
import showToast from "../../../../../../components/Toasts/ShowToast";
import { useTranslation } from "react-i18next";

const WebhookSaveEndpointBtn = ({ title, currentValue, onClickSave, placeholder }) => {
  const [editActive, setEditActive] = useState(true);
  const [value, setValue] = useState(currentValue ?? "");
  useEffect(() => {
    if (currentValue) {
      setValue(currentValue);
      setEditActive(false);
    }
  }, [currentValue]);

  const onClickSaveBtn = () => {
    if (value) {
      onClickSave(value);
      setEditActive(false);
      showToast("Endpoint saved successfully");

    } else {
      showToast("Please enter valid value");
    }
  };
  const onClickEdit = () => {
    setEditActive(true);
  };
  const onChangeValue = (e) => {
    setValue(e.target.value);
  };
  const { t } = useTranslation()

  return (
    <div className="save-endpoint-btn-con d-flex-col">
      <p>{title}</p>
      <div className="d-flex">
        <input
          onChange={onChangeValue}
          placeholder={placeholder ?? "Webhook URL"}
          className={editActive ? "disabled-input" : "enabled-input"}
          value={value}
          type="text"
          disabled={editActive ? false : true}
        />
        {currentValue ? (
          editActive ? (
            <button className="save-btn" onClick={onClickSaveBtn}>
              {t("save.message")}
            </button>
          ) : (
            <button className="edit-btn" onClick={onClickEdit}>
              {t("edit.message")}
            </button>
          )
        ) : (
          <button className="save-btn" onClick={onClickSaveBtn}>
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default WebhookSaveEndpointBtn;
